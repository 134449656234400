import Utils from "../utils";
import { AnyRecord } from "dns";

class Company {
  companyName: string = "";
  countryCode: string = "";
  mobileFormat: string = "";
  mobile: string = "";
  otp: string = "";
  otpExpireTime: string = "";
  isOtpVerified: string = "";
}
class LoginSession {
  device_token: string = "";
  device_type: string = "";
  fcm_token: string = "";
  authToken: string = "";
  loginStatus: boolean = false;
  _id: string = "";
  device_id: string = "";
}
class Location {
  type: any = "";
  coordinates: Array<any> = [0, 0];
}

class ErrorResponseModal {
  data: any;
}
class UserDataModal {
  _id: string = "";
  company: Company = new Company();
  location: Location = new Location();
  password: string = "";
  status: number = 0;
  lat: number = 0;
  lng: number = 0;
  role:string ="";
  twitter_id: string = "";
  socialId: string = "";
  socialPlatform: number = 0;
  google_id: string = "";
  user_image: string = "";
  gender: number = 0;
  user_type: number = 0;
  date_created: string = "";
  educationForMatch: Array<any> = [];
  shareUrl: string = "";
  isExperience: number = 0;
  isNotify: number = 0;
  jobType: number = 0;
  jobApplyCount: number = 0;
  jobCreatedCount: number = 0;
  profileView: number = 0;
  starsUsedCount: number = 0;
  isSearch: number = 0;
  addedVia: number = 0;
  isEmailVerify: number = 0;
  authToken: string = "";
  callStatus: string = "";
  isLogin: boolean = false;
  is_profile_added: number = 0;
  is_exp_added: number = 0;
  stars: number = 0;
  freeStars: number = 0;
  visitCount: number = 0;
  isEmailSubscribe: number = 0;
  isTextMessage: number = 0;
  messageTemplate: string = "";
  isRead: number = 0;
  isRefer: boolean = false;
  referralCount: number = 0;
  contract: Array<any> = [];
  canImmediateJoin: number = 0;
  salary: string = "";
  duration: string = "";
  reportedUsers: Array<any> = [];
  first_name: string = "";
  last_name: string = "";
  fullName: string = "";
  email: string = "";
  mobile: string = "";
  phone: string ="";
  created_at: number = 0;
  updated_at: number = 0;
  referralCode: string = "";
  referralUrl: string = "";
  experience: Array<any> = [];
  user_login_session: Array<LoginSession> = [];
  languagePreference: Array<any> = [];
  keySkills: Array<any> = [];
  __v: number = 0;
  totalNotification: number = 0;
  freeStarLimit: number = 0;
  user_id: string = "";
  full_name: string = "";
  inviteUrl: string = "";
  tourEnable: any = {};
  bio: string ="";
site: string ="";
twitterUsername: string ="";
instaUsername: string ="";
username:string ="";
serviceCharges:string ="";
genre:string ="";
userId:string ="";
firstName: string = "";
lastName: string = "";
memberId: string = "";
accountType: string = "";
}

class CategoryModal {
  categoryTitle: string = "";
  categoryImage: string = "";
  selectedImage: string = "";
  categoryImageWeb: string = "";
  selectedCategoryImageWeb: string = "";
  status: number = 0;
  created_at: string = "";
  updated_at: string = "";
  isLastCategory: number = 0;
  _id: string = "";
}
class NFTModal {

  categoryId: string = ""
  created_at: number = 0
  creatorId: string = ""
  date_created: Date = new Date()
  description: string = ""
  expirationDate: number = 0
  filePath: string = ""
  nftType: string = ""
  ownerUserId: string = ""
  price: number = 0
  startBidPrice: number = 0
  title: string = ""
  updated_at: number = 0
  tokenId: number = -1
  _id: string = ""
}
class JobListsModal {
  //   company: Company = new Company();
  //   jobs: Jobs = new Jobs();
  keySkills: Array<Jobs> = [];
}
class Jobs {
  _id: string = "";
  jobTitle: string = "";
  jobDesc: string = "";
  jobImage: string = "";
  address: string = "";
  jobCity: string = "";
  jobState: string = "";
  latitude: number = 0;
  longitude: number = 0;
  salaryFrom: number = 0;
  salaryTo: number = 0;
  duration: string = "";
  extraCompensation: string = "";
  isExp: number = 0;
  fromExperience: number = 0;
  toExperience: number = 0;
  shareUrl: string = "";
  companyName: string = "";
  companyDesc: string = "";
  companyWebsite: string = "";
  companyAddress: string = "";
  companyLatitude: number = 0;
  companyLongitude: number = 0;
  planId: string = "";
  categoryName: string = "";
  // step: object={}
  status: number = 0;
  totalApplyCount: number = 0;
  totalViewCount: number = 0;
  startMonthExp: string = "";
  startYearExp: string = "";
  endMonthExp: string = "";
  endYearExp: string = "";
  totalExperience: string = "";
  experienceType: string = "";
  isCommision: number = 0;
  isPremium: number = 0;
  created_at: number = 0;
  updated_at: number = 0;
  date_created: string = "";
  premiumStart: string = "";
  premiumEnd: string = "";
  reason: string = "";
  jobReportBy: Array<any> = [];
  shortlistUsers: Array<any> = [];
  jobOwnerReportBy: Array<any> = [];
  // contract: [
  //     1,
  //     2
  // ],
  // immediateJoiner: number=0
  // "educationForMatch": [
  //     1,
  //     4
  // ],
  // "keySkills": [
  //     {
  //         "skillId": "5ea098d36093063f1c229dd4",
  //         "skill": "Communication"
  //     }
  // ],
  // "recruiterId": string = "",
  // "noticePeriod": number = 0
  // "languagePreference": [
  //     {
  //         "language": "Dutch",
  //         "languageId": "5ea091400f2af437a463f7e8"
  //     },
  //     {
  //         "languageId": "5ea0916b0f2af437a463f7e9",
  //         "language": "English"
  //     }
  // ],
  // "jobType": number = 0
  // "categoryId": string = ""
  // "appliedUsers": [
  //     "https://grabjob.s3.eu-central-1.amazonaws.com/android/android1588070908175.jpg",
  //     ""
  // ],
  "__v": number = 0;
}

class jobDetailModal {
  any: any;
}

class candidateStatusModal {
  data: any;
}

class tourEnableModal {
  data: any;
}

class candidateProfileModel {
  data: any;
}

class candidateListModel {
  data: any;
}

class shortListingModel {
  data: any;
}

class rejectCandidateModel {
  data: any;
}

class recruiterProfileModel {
  data: any;
}

class queryMailModel {
  data: any;
}

class dashboardRecruiterProfileModel {
  data: any;
}

class updateProfileRecruiterModel {
  data: any;
}

class userStoryModel {
  data: any;
}

class SelectCategoryModel {
  data: any;
}

class userProfile {
  data: any;
}

class postJobModel {
  data: any;
}
class updateJobModel {
  data: any;
}

class changePasswordModel {
  data: any;
}

class contactUsModel {
  data: any;
}

class reviewModel {
  data: any;
}

class languageListModal {
  data: any;
}

class skillsListModal {
  data: any;
}

class templateModel {
  data: any;
}

class roomModel {
  data: any;
}

class refferalcountModel {
  data: any;
}
class starPackageModel {
  data: any;
}

class deleteAccountModel {
  data: any;
}

class otpModel {
  data: any;
}

class paymentModel {
  data: any;
}

export {
  UserDataModal,
  ErrorResponseModal,
  CategoryModal,
  JobListsModal,
  jobDetailModal,
  candidateStatusModal,
  candidateProfileModel,
  recruiterProfileModel,
  updateProfileRecruiterModel,
  otpModel,
  dashboardRecruiterProfileModel,
  candidateListModel,
  SelectCategoryModel,
  postJobModel,
  updateJobModel,
  changePasswordModel,
  contactUsModel,
  reviewModel,
  refferalcountModel,
  deleteAccountModel,
  starPackageModel,
  shortListingModel,
  rejectCandidateModel,
  languageListModal,
  skillsListModal,
  templateModel,
  roomModel,
  userStoryModel,
  queryMailModel,
  paymentModel,
  tourEnableModal,
  NFTModal,
  userProfile
};
