import React from "react";
// import Account from '../modules/auth/screen/account.screen';
import Header from "../component/header/header";
import Footer from '../component/footer/footer'
//import "../assets/css/style.css";
interface Props {
  t: any;
  history: any;
  showOTPModal: boolean;
  toggleOTPModal: Function;
}

function higherOrderComponent(WrappedComponent: any) {
  class HOCC extends React.Component<Props, any> {
    constructor(props: Props) {
      super(props);
    }
    render() {
      return (
        <>
            <Header></Header>
          {/* <Toast /> */}
          <WrappedComponent {...this.props} />
          <Footer></Footer>
        </>
      );
    }
  }

  return HOCC;
};
export default higherOrderComponent;