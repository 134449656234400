import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
    t: any;
    sendMessage: Function;
    loginResponce?: any;

}
interface State {
    email: string;
    showLoginLoading: boolean;
    emailColor: string;
    textArea: string;
    emailResponse: string;
    isWhitePaperRequest: boolean;
    error: {
        emailError: string;
    };
}

export class SharedHostingScreen extends PureComponent<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            emailResponse: "",
            emailColor: "#FFFFFF",
            textArea: "",
            isWhitePaperRequest: false,
            showLoginLoading: false,
            error: {
                emailError: "",
            },
        };
    }

    componentDidMount() {
        console.log(window.location.search);

        if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
            this.setState({ isWhitePaperRequest: true });
        }
    }
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        console.log('handled')

        let { email, textArea, error } = this.state;

        let formError = Utils.constant.validateForm({ email, textArea, error });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");

        this.setState({ error: Object.assign({}, formError) }, () => {
            if (this.state.error.emailError != "") {
                console.log(this.state.error)
                this.setState({ emailColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ emailColor: "#121212" })
            }

        });
        if (!isValid) {
            return false;
        } else {

            this.setState({ showLoginLoading: true });

            const token = await localStorage.getItem("fcmToken");

            let payload = this.state.isWhitePaperRequest ? { email, textArea, isWhitePaperRequest: "true" } : { email, textArea };

            this.props.sendMessage(payload, (response: any) => {
                this.setState(
                    {
                        email: "",
                        textArea: ""
                    },
                    () => {
                        this.setState({ emailResponse: "Sent" })

                        let element = document.getElementById("body-message");

                        if (element) {
                            element.classList.add("active");

                            let errorElement = document.getElementById("api-error-message");

                            if (errorElement) {
                                element.style.backgroundColor = "#00baee";
                                element.style.borderColor = "#00baee";
                                errorElement.innerHTML = "Sent";
                            }
                            setTimeout(function () {
                                if (element) {
                                    element.classList.remove("active");
                                }
                                if (errorElement) {
                                    errorElement.innerHTML = "";
                                }
                            }, 3000);
                        }

                    }
                );
            });
        }
    };

    // reset=( )=>{

    //   this.setState({

    //       email: "",
    //       emailResponse: "",
    //       emailColor: "#FFFFFF",
    //       textArea: "",
    //       isWhitePaperRequest:false,
    //       showLoginLoading: false,
    //       error: {
    //       emailError: "",
    //      },
    //   })

    // };

    render() {

        return (
            <>
                {/* <!-- Breadcrumps --> */}
                <div className="breadcrumbs">
                    <div className="row">
                        <div className="col-sm-6">
                            <h1>Shared Hosting</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li>You are here: </li>
                                <li><a href="index-2.html">Home</a>
                                </li>
                                <li><a href="#">Hosting</a>
                                </li>
                                <li className="active">Shared Hosting</li>
                            </ol>
                        </div>
                    </div>
                </div>

                {/* <!-- End of Breadcrumps --> */}


                {/* <!--  Pricing Tables --> */}
                <section className="pricingtables shared">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Pick the plan that suits you most</h2>
                            <p>Our grantees and partners partner contribution carbon emissions reductions Rockefeller</p>
                            <span className="chooseprice">Choose price per period</span>
                            <div className="btn-group price-per-period" role="group">
                                <button type="button" className="btn btn-shared-checked permonth">Monthly</button>
                                <button type="button" className="btn btn-default peryear">Annually</button>
                                <button type="button" className="btn btn-default per2yrs">Biennially</button>
                            </div>

                        </div>
                    </div>

                    <div className="row spacing-40 no-gutter">

                        <div className="col-sm-3 wow fadeInUp hostingfeatures" data-wow-delay="0.2s">
                            <div className="panel panel-info">
                                <div className="panel-heading">
                                </div>
                                <div className="panel-body text-center">
                                </div>
                                <ul className="text-left">
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Disk Space</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Data Transfer</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Domains</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Free Setup</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Disk Space</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Data Transfer</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Domains</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Free Setup</a>
                                    </li>
                                    <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Protect, Andrew Carnegie enabler">Disk Space</a>
                                    </li>
                                </ul>
                                <div className="panel-footer">

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 wow fadeInUp" data-wow-delay="0.4s" id="tab-1">
                            <div className="panel panel-info">
                                <div className="panel-heading">
                                    <h3 className="text-center">Basic</h3>
                                </div>
                                <div className="panel-body text-center">
                                    <div className="monthprice">
                                        <h4>$9</h4>
                                        <span className="per">PER MONTH</span>
                                    </div>
                                    <div className="yearprice">
                                        <h4>$91.80</h4>
                                        <span className="per">PER YEAR</span>
                                    </div>
                                    <div className="twoyearprice">
                                        <h4>$151.20</h4>
                                        <span className="per">PER 2 YEARS</span>
                                    </div>
                                </div>
                                <ul className="text-center">
                                    <li>1GB SSD</li>
                                    <li>100GB</li>
                                    <li>1</li>
                                    <li><i className="fa fa-times"></i>
                                        <div className="visible-xs">Free Setup</div>
                                    </li>
                                    <li>1GB SSD</li>
                                    <li>100GB</li>
                                    <li>1</li>
                                    <li><i className="fa fa-times"></i>
                                        <div className="visible-xs">Free Setup</div>
                                    </li>
                                    <li>1GB SSD</li>
                                </ul>
                                <div className="panel-footer">
                                    <a className="btn btn-lg btn-pricetable" href="#">ORDER NOW</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 wow fadeInUp most-popular" data-wow-delay="0.6s" id="tab-2">
                            <div className="panel panel-info">
                                <div className="panel-heading">
                                    <h3 className="text-center">Advanced</h3>
                                </div>
                                <div className="panel-body text-center">
                                    <div className="monthprice">
                                        <h4>$17</h4>
                                        <span className="per">PER MONTH</span>
                                    </div>
                                    <div className="yearprice">
                                        <h4>$173.40</h4>
                                        <span className="per">PER YEAR</span>
                                    </div>
                                    <div className="twoyearprice">
                                        <h4>$285.60</h4>
                                        <span className="per">PER 2 YEARS</span>
                                    </div>
                                </div>
                                <ul className="text-center">
                                    <li>2GB SSD</li>
                                    <li>200GB</li>
                                    <li>2</li>
                                    <li><i className="fa fa-check"></i>
                                        <div className="visible-xs">Free Setup</div>
                                    </li>
                                    <li>2GB SSD</li>
                                    <li>200GB</li>
                                    <li>2</li>
                                    <li><i className="fa fa-check"></i>
                                        <div className="visible-xs">Free Setup</div>
                                    </li>
                                    <li>2GB SSD</li>
                                </ul>
                                <div className="panel-footer">
                                    <a className="btn btn-lg btn-pricetable" href="#">ORDER NOW</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="panel panel-info">
                                <div className="panel-heading">
                                    <h3 className="text-center">Professional</h3>
                                </div>
                                <div className="panel-body text-center">
                                    <div className="monthprice">
                                        <h4>$25</h4>
                                        <span className="per">PER MONTH</span>
                                    </div>
                                    <div className="yearprice">
                                        <h4>$255</h4>
                                        <span className="per">PER YEAR</span>
                                    </div>
                                    <div className="twoyearprice">
                                        <h4>$420</h4>
                                        <span className="per">PER 2 YEARS</span>
                                    </div>
                                </div>
                                <ul className="text-center">
                                    <li>3GB SSD</li>
                                    <li>300GB</li>
                                    <li>3</li>
                                    <li><i className="fa fa-check"></i>
                                        <div className="visible-xs">Free Setup</div>
                                    </li>
                                    <li>3GB SSD</li>
                                    <li>300GB</li>
                                    <li>3</li>
                                    <li><i className="fa fa-check"></i>
                                        <div className="visible-xs">Free Setup</div>
                                    </li>
                                    <li>3GB SSD</li>
                                </ul>
                                <div className="panel-footer">
                                    <a className="btn btn-lg btn-pricetable" href="#">ORDER NOW</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* <!-- End of Pricing Tables --> */}



                
            </>
            
        );
    }
}
function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        sendMessage: (payload: any, callback: Function) =>
            dispatch(sendMessage(payload, callback)),
    };
}

export const SharedHostingModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(SharedHostingScreen)
);

export const SharedHosting = AppLayout(SharedHostingModule);
