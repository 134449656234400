import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '../'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
    t: any;
    sendMessage: Function;
    loginResponce?: any;

}
interface State {
    email: string;
    showLoginLoading: boolean;
    emailColor: string;
    textArea: string;
    emailResponse: string;
    isWhitePaperRequest: boolean;
    error: {
        emailError: string;
    };
}

export class AboutUsScreen extends PureComponent<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            emailResponse: "",
            emailColor: "#FFFFFF",
            textArea: "",
            isWhitePaperRequest: false,
            showLoginLoading: false,
            error: {
                emailError: "",
            },
        };
    }

    componentDidMount() {

        // const script = document.createElement("script");
        // script.src = "assets/js/main.js";
        // script.async = true;
        // document.body.appendChild(script);
        // console.log(window.location.search);

        if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
            this.setState({ isWhitePaperRequest: true });
        }
    }
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        console.log('handled')

        let { email, textArea, error } = this.state;

        let formError = Utils.constant.validateForm({ email, textArea, error });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");

        this.setState({ error: Object.assign({}, formError) }, () => {
            if (this.state.error.emailError != "") {
                console.log(this.state.error)
                this.setState({ emailColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ emailColor: "#121212" })
            }

        });
        if (!isValid) {
            return false;
        } else {

            this.setState({ showLoginLoading: true });

            const token = await localStorage.getItem("fcmToken");

            let payload = this.state.isWhitePaperRequest ? { email, textArea, isWhitePaperRequest: "true" } : { email, textArea };

            this.props.sendMessage(payload, (response: any) => {
                this.setState(
                    {
                        email: "",
                        textArea: ""
                    },
                    () => {
                        this.setState({ emailResponse: "Sent" })

                        let element = document.getElementById("body-message");

                        if (element) {
                            element.classList.add("active");

                            let errorElement = document.getElementById("api-error-message");

                            if (errorElement) {
                                element.style.backgroundColor = "#00baee";
                                element.style.borderColor = "#00baee";
                                errorElement.innerHTML = "Sent";
                            }
                            setTimeout(function () {
                                if (element) {
                                    element.classList.remove("active");
                                }
                                if (errorElement) {
                                    errorElement.innerHTML = "";
                                }
                            }, 3000);
                        }

                    }
                );
            });
        }
    };

    // reset=( )=>{

    //   this.setState({

    //       email: "",
    //       emailResponse: "",
    //       emailColor: "#FFFFFF",
    //       textArea: "",
    //       isWhitePaperRequest:false,
    //       showLoginLoading: false,
    //       error: {
    //       emailError: "",
    //      },
    //   })

    // };

    render() {

        return (
            <>
                {/* <!-- page-banner start --> */}
                <section className="banner banner__2 overflow-hidden" style={{ backgroundImage: `url(assets/img/home-4/banner_01.png)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner__content text-center m-auto">
                                    <span className="sub-title fw-500  text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">Welcome To MetaLink</span>
                                    <h1 className="title mb-sm-10 mb-xs-5 mb-15">Know About Our <span> Company </span></h1>

                                    {/* <div className="description font-la mb-40 mb-lg-35 mb-sm-30 mb-xs-25">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div> */}

                                    <a href="/" className="theme-btn btn__2">Home<i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- page-banner end --> */}

                {/* <!-- our-company start --> */}
                <section className="our-company  pt-xs-80 pb-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="our-company__meida">
                                    <img src="assets/img/about/our-company-1.png" alt="" className="img-fluid" />

                                    <div className="years-experience overflow-hidden mt-20 mt-sm-10 mt-xs-10 text-center">
                                        <div className="number mb-5 color-white">
                                            <span className="counter">23</span><sup>+</sup>
                                        </div>

                                        <h5 className="title color-white">Years Experience</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="our-company__meida border-radius">
                                    <img src="assets/img/about/our-company-2.png" alt="" className="img-fluid" />

                                    <div className="horizental-bar"></div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="our-company__content mt-md-50 mt-sm-40 mt-xs-35">
                                    <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-20 d-block"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Know Our Company</span>
                                    <h2 className="title color-d_black mb-20 mb-sm-15 mb-xs-10">Our Company Provide High Quality Idea</h2>

                                    <div className="descriiption font-la mb-30 mb-md-25 mb-sm-20 mb-xs-15">
                                        <p>At MetaLink, we are dedicated to revolutionizing the way businesses operate in today's dynamic landscape. With a profound understanding of diverse industries, we specialize in four key areas: HR Services, Fintech Solutions, Customer Support, and Cutting-Edge Technology Services.</p>
                                    </div>
                                    <h4 className="title color-d_black">Our Vision</h4>
                                    <div className="descriiption font-la mb-30 mb-md-25 mb-sm-20 mb-xs-15">
                                        <p>We envision a world where businesses thrive through innovation, strategic insights, and seamless operations. By combining expertise in HR, Fintech, Customer, and Technology services, we are committed to shaping a brighter future for our clients.</p>
                                    </div>

                                    {/* <div className="client-feedback d-flex flex-column flex-sm-row">
                                        <div className="client-feedback__item text-center">
                                            <div className="client-feedback__item-header">
                                                <span className="color-primary font-la fw-600 text-uppercase">Success Project</span>
                                            </div>

                                            <div className="client-feedback__item-body">
                                                <div className="number mb-10 mb-xs-5 color-d_black fw-600">+<span className="counter">95</span>%</div>
                                                <div className="description font-la mb-10 mb-xs-5">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do.</p>
                                                </div>
                                                <div className="starts">
                                                    <ul>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="client-feedback__item text-center">
                                            <div className="client-feedback__item-header">
                                                <span className="color-primary font-la fw-600 text-uppercase">Customer Review</span>
                                            </div>

                                            <div className="client-feedback__item-body">
                                                <div className="number mb-10 mb-xs-5 color-d_black fw-600">+<span className="counter">4.7</span></div>
                                                <div className="description font-la mb-10 mb-xs-5">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do.</p>
                                                </div>
                                                <div className="starts">
                                                    <ul>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                        <li><span></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- our-company end --> */}

                {/* <!-- employee-friendly start --> */}
                <section className="employee-friendly bg-dark_white pt-sm-60 pt-xs-80 pb-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-9">
                                <div className="employee-friendly__content">
                                    <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Services</span>
                                    <h2 className="title color-d_black">Friendly <span>Service</span></h2>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="slider-controls mt-sm-30">
                                    <div className="slider-arrows d-flex align-content-center justify-content-sm-end"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="employee-friendly__slider mt-65 mt-md-50 mt-sm-40 mt-xs-30">
                                    <div className="slider-item">
                                        <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                                            <div className="top d-flex align-items-center">
                                                <div className="icon color-white text-center">
                                                    <i className="fal fa-analytics"></i>
                                                </div>

                                                <h4 className="title color-d_black"><a href="/services">HR &amp; Service</a></h4>
                                            </div>

                                            <div className="bottom">
                                                <div className="media overflow-hidden">
                                                    <img src="assets/img/services/services-2.png" className="img-fluid" alt="" />
                                                </div>

                                                <a href="/services" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                                            <div className="top d-flex align-items-center">
                                                <div className="icon color-white text-center">
                                                    <i className="icon-planning"></i>
                                                </div>

                                                <h4 className="title color-d_black"><a href="/services">Fintech &amp; Services</a></h4>
                                            </div>

                                            <div className="bottom">
                                                <div className="media overflow-hidden">
                                                    <img src="assets/img/project-details/project-details-2.png" className="img-fluid" alt="" />
                                                </div>

                                                <a href="/services" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                                            <div className="top d-flex align-items-center">
                                                <div className="icon color-white text-center">
                                                    <i className="icon-outline"></i>
                                                </div>

                                                <h4 className="title color-d_black"><a href="/services">Technology Services</a></h4>
                                            </div>

                                            <div className="bottom">
                                                <div className="media overflow-hidden">
                                                    <img src="assets/img/project-details/project-details-3.png" className="img-fluid" alt="" />
                                                </div>

                                                <a href="/services" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                                            <div className="top d-flex align-items-center">
                                                <div className="icon color-white text-center">
                                                    <i className="fal fa-analytics"></i>
                                                </div>

                                                <h4 className="title color-d_black"><a href="/services">HR &amp; Service</a></h4>
                                            </div>

                                            <div className="bottom">
                                                <div className="media overflow-hidden">
                                                    <img src="assets/img/services/services-2.png" className="img-fluid" alt="" />
                                                </div>

                                                <a href="/services" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                                            <div className="top d-flex align-items-center">
                                                <div className="icon color-white text-center">
                                                    <i className="icon-planning"></i>
                                                </div>

                                                <h4 className="title color-d_black"><a href="/services">Fintech &amp; Services</a></h4>
                                            </div>

                                            <div className="bottom">
                                                <div className="media overflow-hidden">
                                                    <img src="assets/img/project-details/project-details-2.png" className="img-fluid" alt="" />
                                                </div>

                                                <a href="/services" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                                            <div className="top d-flex align-items-center">
                                                <div className="icon color-white text-center">
                                                    <i className="icon-outline"></i>
                                                </div>

                                                <h4 className="title color-d_black"><a href="/services">Technology Services</a></h4>
                                            </div>

                                            <div className="bottom">
                                                <div className="media overflow-hidden">
                                                    <img src="assets/img/project-details/project-details-3.png" className="img-fluid" alt="" />
                                                </div>

                                                <a href="/services" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- employee-friendly end --> */}

                {/* <!-- company-skill start --> */}
                <section className="company-skill pt-xs-80 pb-xs-80 pt-sm-100 pt-md-100 pt-120 pb-100 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="company-skill__content">
                                    <span className="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-20"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" />Company Skills</span>
                                    <h2 className="title color-pd_black mb-25 mb-xs-10 mb-sm-15">Our Company Provide <span>High Quality Idea</span></h2>

                                    <div className="description font-la">
                                        <p>We take pride in delivering exceptional services that fullfil the unique needs of our clients. With a team of seasoned professionals, we are committed to providing innovative, smart solutions that drive growth and ensure sustainable success.</p>
                                    </div>

                                    <div className="progress-bar__wrapper mt-40 mt-sm-35 mt-xs-30">
                                        <div className="single-progress-bar mb-30">
                                            <h6 className="title color-d_black mb-10">Design</h6>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} style={{ maxWidth: "80%" }}>
                                                    <span className="placeholder" style={{ left: "80%" }}>80%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-progress-bar mb-30">
                                            <h6 className="title color-d_black mb-10">Development</h6>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={55} aria-valuemin={0} aria-valuemax={100} style={{ maxWidth: "55%" }}>
                                                    <span className="placeholder" style={{ left: "55%" }}>55%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="single-progress-bar mb-30">
                                            <h6 className="title color-d_black mb-10">Application</h6>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{ maxWidth: "90%" }}>
                                                    <span className="placeholder" style={{ left: "90%" }}>90%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center">
                                    {/* <a href="https://www.youtube.com/watch?v=9xwazD5SyVg" className="popup-video" data-effect="mfp-move-from-top"><i className="icon-play"></i></a> */}

                                    <div className="company-skill__media">
                                        <img src="assets/img/about/company-skill-meida.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- company-skill end --> */}

                {/* <!-- counter-area start --> */}
                <div className="counter-area pb-xs-80 pb-sm-120 pb-md-120 pb-lg-120 pb-xl-140 pb-170 overflow-hidden">
                    <div className="container">
                        <div className="row mb-minus-30">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item d-flex align-items-center">
                                    <div className="icon color-primary">
                                        <i className="icon-process-1"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-primary"><span className="counter">5620</span>+</div>
                                        <div className="description font-la">Successful Project</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item d-flex align-items-center">
                                    <div className="icon color-primary">
                                        <i className="icon-support-2"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-primary"><span className="counter">150</span>+</div>
                                        <div className="description font-la">Expert Consultancy</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item d-flex align-items-center">
                                    <div className="icon color-primary">
                                        <i className="icon-coffee-2"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-primary"><span className="counter">96</span>+</div>
                                        <div className="description font-la">Cup Of Cofee</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item d-flex align-items-center">
                                    <div className="icon color-primary">
                                        <i className="icon-teamwork-1"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-primary"><span className="counter">3225</span>+</div>
                                        <div className="description font-la">Client Satisfaction</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- counter-area end --> */}

                {/* <!-- our-team start --> */}
                {/* <section className="our-team bg-dark_white pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="our-team__content mb-65 mb-md-50 mb-sm-40 mb-xs-30 text-center">
                                    <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Our Team</span>
                                    <h2 className="title color-d_black">Meet Our Team <span>Member</span></h2>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-minus-30">
                            <div className="col-xxl-3 col-lg-4 col-md-6">
                                <a href="team-details.html" className="team-item team-about-item text-center mb-30 d-block overflow-hidden">
                                    <div className="media">
                                        <img src="assets/img/about/about-member-1.png" className="img-fluid" alt="" />
                                    </div>

                                    <div className="text d-flex align-items-center justify-content-center">
                                        <div className="left">
                                            <h5 className="title color-white">Stephen Larry</h5>
                                            <span className="position color-white font-la fw-500">Product Manager</span>
                                        </div>

                                        <div className="right">
                                            <div className="icon">
                                                <i className="icon-arrow-right-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            

                            <div className="col-xxl-3 col-lg-4 col-md-6">
                                <a href="team-details.html" className="team-item team-about-item text-center mb-30 d-block overflow-hidden">
                                    <div className="media">
                                        <img src="assets/img/about/about-member-2.png" className="img-fluid" alt="" />
                                    </div>

                                    <div className="text d-flex align-items-center justify-content-center">
                                        <div className="left">
                                            <h5 className="title color-white">Nusrat Jahan</h5>
                                            <span className="position color-white font-la fw-500">Assistent Manager, Finance</span>
                                        </div>

                                        <div className="right">
                                            <div className="icon">
                                                <i className="icon-arrow-right-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            

                            <div className="col-xxl-3 col-lg-4 col-md-6">
                                <a href="team-details.html" className="team-item team-about-item text-center mb-30 d-block overflow-hidden">
                                    <div className="media">
                                        <img src="assets/img/about/about-member-3.png" className="img-fluid" alt="" />
                                    </div>

                                    <div className="text d-flex align-items-center justify-content-center">
                                        <div className="left">
                                            <h5 className="title color-white">Stephen Larry</h5>
                                            <span className="position color-white font-la fw-500">Product Manager</span>
                                        </div>

                                        <div className="right">
                                            <div className="icon">
                                                <i className="icon-arrow-right-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            

                            <div className="col-xxl-3 col-lg-4 col-md-6">
                                <a href="team-details.html" className="team-item team-about-item text-center mb-30 d-block overflow-hidden">
                                    <div className="media">
                                        <img src="assets/img/about/about-member-4.png" className="img-fluid" alt="" />
                                    </div>

                                    <div className="text d-flex align-items-center justify-content-center">
                                        <div className="left">
                                            <h5 className="title color-white">Stephen Larry</h5>
                                            <span className="position color-white font-la fw-500">Product Manager</span>
                                        </div>

                                        <div className="right">
                                            <div className="icon">
                                                <i className="icon-arrow-right-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="our-team__btn-wrapper text-center mt-70 mt-md-50 mt-sm-40 mt-xs-30">
                                    <a href="team.html" className="theme-btn">See All Member <i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <!-- our-team end --> */}

                <div className="testimonial-bg" style={{ backgroundImage: `url(assets/img/about/testimonial-bg.png)` }}>
                    {/* <!-- testimonial start --> */}
                    <section className="testimonial pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-110 overflow-hidden">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="testimonial__content mb-60 mb-md-50 mb-sm-40 mb-xs-30 text-center">
                                        <h6 className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Testimonials</h6>
                                        <h2 className="title color-d_black">MetaLink <span>Customer Feedback</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container container_testimonial">
                            <div className="row">
                                <div className="col-12">
                                    <div className="testimonial-slider">
                                        <div className="slider-item">
                                            <div className="testimonial__item">
                                                <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                    <div className="left d-flex align-items-center">
                                                        <div className="media overflow-hidden">
                                                            <img src="assets/img/testimonial/testimonial-1.png" className="img-fluid" alt="" />
                                                        </div>

                                                        <div className="meta">
                                                            <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam </h6>
                                                            <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                        </div>
                                                    </div>

                                                    <div className="right">
                                                        <div className="starts">
                                                            <ul>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                                </div>

                                                <div className="testimonial__item-footer d-flex justify-content-between">
                                                    <div className="socail-link">
                                                        <ul>
                                                            <li><a href="#"><img src="assets/img/testimonial/discord.png" alt="" /></a></li>
                                                            <li><a href="#"><img src="assets/img/testimonial/asana.png" alt="" /></a></li>
                                                        </ul>
                                                    </div>

                                                    <div className="quote color-primary">
                                                        <i className="fas fa-quote-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="slider-item">
                                            <div className="testimonial__item border--primary">
                                                <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                    <div className="left d-flex align-items-center">
                                                        <div className="media overflow-hidden">
                                                            <img src="assets/img/testimonial/testimonial-2.png" className="img-fluid" alt="" />
                                                        </div>

                                                        <div className="meta">
                                                            <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                            <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                        </div>
                                                    </div>

                                                    <div className="right">
                                                        <div className="starts">
                                                            <ul>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                                </div>

                                                <div className="testimonial__item-footer d-flex justify-content-between">
                                                    <div className="socail-link">
                                                        <ul>
                                                            <li><a href="#"><img src="assets/img/testimonial/asana.png" alt="" /></a></li>
                                                        </ul>
                                                    </div>

                                                    <div className="quote color-primary">
                                                        <i className="fas fa-quote-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="slider-item">
                                            <div className="testimonial__item border-red">
                                                <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                    <div className="left d-flex align-items-center">
                                                        <div className="media overflow-hidden">
                                                            <img src="assets/img/testimonial/testimonial-3.png" className="img-fluid" alt="" />
                                                        </div>

                                                        <div className="meta">
                                                            <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                            <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                        </div>
                                                    </div>

                                                    <div className="right">
                                                        <div className="starts">
                                                            <ul>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                                </div>

                                                <div className="testimonial__item-footer d-flex justify-content-between">
                                                    <div className="socail-link">
                                                        <ul>
                                                            <li><a href="#"><img src="assets/img/testimonial/amazon.png" alt="" /></a></li>
                                                        </ul>
                                                    </div>

                                                    <div className="quote color-primary">
                                                        <i className="fas fa-quote-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="slider-item">
                                            <div className="testimonial__item border--primary">
                                                <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                    <div className="left d-flex align-items-center">
                                                        <div className="media overflow-hidden">
                                                            <img src="assets/img/testimonial/testimonial-2.png" className="img-fluid" alt="" />
                                                        </div>

                                                        <div className="meta">
                                                            <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                            <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                        </div>
                                                    </div>

                                                    <div className="right">
                                                        <div className="starts">
                                                            <ul>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                                </div>

                                                <div className="testimonial__item-footer d-flex justify-content-between">
                                                    <div className="socail-link">
                                                        <ul>
                                                            <li><a href="#"><img src="assets/img/testimonial/asana.png" alt="" /></a></li>
                                                        </ul>
                                                    </div>

                                                    <div className="quote color-primary">
                                                        <i className="fas fa-quote-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="slider-item">
                                            <div className="testimonial__item border-red">
                                                <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                    <div className="left d-flex align-items-center">
                                                        <div className="media overflow-hidden">
                                                            <img src="assets/img/testimonial/testimonial-3.png" className="img-fluid" alt="" />
                                                        </div>

                                                        <div className="meta">
                                                            <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                            <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                        </div>
                                                    </div>

                                                    <div className="right">
                                                        <div className="starts">
                                                            <ul>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                                <li><span></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                                </div>

                                                <div className="testimonial__item-footer d-flex justify-content-between">
                                                    <div className="socail-link">
                                                        <ul>
                                                            <li><a href="#"><img src="assets/img/testimonial/amazon.png" alt="" /></a></li>
                                                        </ul>
                                                    </div>

                                                    <div className="quote color-primary">
                                                        <i className="fas fa-quote-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- testimonial end --> */}

                    {/* <!-- client-brand start --> */}
                    <div className="client-brand  pb-xs-80 pb-md-100 pb-sm-100 pb-lg-100 pb-105 overflow-hidden">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="client-brand__slider" data-slick='{
                            "dots": "false", 
                            "arrows": "false",
                            "autoplay": "true",
                            "slidesToShow": 6,
                            "infinite": "true",
                            "slidesToScroll": 1,
                            "autoplaySpeed": 500,
                            "responsive": [
                                {
                                    "breakpoint": 1300,
                                    "settings": {
                                    "slidesToShow": 5
                                    }
                                },    
                                {
                                "breakpoint": 1200,
                                "settings": {
                                    "slidesToShow": 4
                                }
                                },
                                {
                                    "breakpoint": 992,
                                    "settings": {
                                    "slidesToShow": 3
                                    }
                                },
                                {
                                    "breakpoint": 768,
                                    "settings": {
                                    "slidesToShow": 2
                                    }
                                },
                                {
                                "breakpoint": 481,
                                "settings": {
                                    "slidesToShow": 1
                                }
                                }
                            ]
                            }'>
                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-1.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-2.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-3.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-4.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-5.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-6.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-1.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-2.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-3.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-4.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-5.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>

                                        <div className="slider-item">
                                            <a href="#" className="client-brand__item">
                                                <div className="client-brand__item-media">
                                                    <img src="assets/img/brand/brand-6.png" className="img-fluid" alt="" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- client-brand end --> */}
                </div>
            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        sendMessage: (payload: any, callback: Function) =>
            dispatch(sendMessage(payload, callback)),
    };
}

export const AboutUsModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(AboutUsScreen)
);

export const AboutUs = AppLayout(AboutUsModule);
