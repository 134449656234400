import { JobListsModal } from "../../modal";
import Utils from "../../utils";
export function homeReducer(
  state: JobListsModal = new JobListsModal(),
  action: any
) {
  switch (action.type) {
    case Utils.actionName.HANDEL_JOB_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
