import React from "react";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";

interface Props {
  pageName: any;
}
interface State {}

class OnBoardingBanner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Translation>
        {(t: Function) => (
          <div className="onboarding_col_left">
            <div className="onboarding_container">
              <figure className="header_logo">
                <NavLink to="/">
                  {" "}
                  {/* <img
                    src={require("../../../../assets/images/logo.svg")}
                    alt="grab job"
                  />{" "} */}
                </NavLink>
              </figure>
              <section className="text_column_main">
                <h1>{this.props.pageName !== t("unsubscribe")? t("needcandidates"): t("unsubscribe")}</h1>
                {this.props.pageName !== t("unsubscribe") && <p>{t("immediatelyfindtheright")}</p>}
              </section>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
export default OnBoardingBanner;
