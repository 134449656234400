import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
    t: any;
    sendMessage: Function;
    loginResponce?: any;

}
interface State {
    email: string;
    showLoginLoading: boolean;
    emailColor: string;
    textArea: string;
    emailResponse: string;
    isWhitePaperRequest: boolean;
    error: {
        emailError: string;
    };
}

export class DomainScreen extends PureComponent<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            emailResponse: "",
            emailColor: "#FFFFFF",
            textArea: "",
            isWhitePaperRequest: false,
            showLoginLoading: false,
            error: {
                emailError: "",
            },
        };
    }

    componentDidMount() {

        console.log(window.location.search);

        if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
            this.setState({ isWhitePaperRequest: true });
        }
    }
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        console.log('handled')

        let { email, textArea, error } = this.state;

        let formError = Utils.constant.validateForm({ email, textArea, error });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");

        this.setState({ error: Object.assign({}, formError) }, () => {
            if (this.state.error.emailError != "") {
                console.log(this.state.error)
                this.setState({ emailColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ emailColor: "#121212" })
            }

        });
        if (!isValid) {
            return false;
        } else {

            this.setState({ showLoginLoading: true });

            const token = await localStorage.getItem("fcmToken");

            let payload = this.state.isWhitePaperRequest ? { email, textArea, isWhitePaperRequest: "true" } : { email, textArea };

            this.props.sendMessage(payload, (response: any) => {
                this.setState(
                    {
                        email: "",
                        textArea: ""
                    },
                    () => {
                        this.setState({ emailResponse: "Sent" })

                        let element = document.getElementById("body-message");

                        if (element) {
                            element.classList.add("active");

                            let errorElement = document.getElementById("api-error-message");

                            if (errorElement) {
                                element.style.backgroundColor = "#00baee";
                                element.style.borderColor = "#00baee";
                                errorElement.innerHTML = "Sent";
                            }
                            setTimeout(function () {
                                if (element) {
                                    element.classList.remove("active");
                                }
                                if (errorElement) {
                                    errorElement.innerHTML = "";
                                }
                            }, 3000);
                        }

                    }
                );
            });
        }
    };

    // reset=( )=>{

    //   this.setState({

    //       email: "",
    //       emailResponse: "",
    //       emailColor: "#FFFFFF",
    //       textArea: "",
    //       isWhitePaperRequest:false,
    //       showLoginLoading: false,
    //       error: {
    //       emailError: "",
    //      },
    //   })

    // };

    render() {

        return (
            <>
                {/* <!-- Breadcrumps --> */}
                <div className="breadcrumbs">
                    <div className="row">
                        <div className="col-sm-6">
                            <h1>Domains</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li>You are here: </li>
                                <li><a href="index-2.html">Home</a>
                                </li>
                                <li className="active">Domains</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- End of Breadcrumps --> */}

                {/* <!--  Domain Search --> */}
                <section className="domains">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Much like a fingerprint. Select the right domain name</h2>
                            <hr className="small" />
                            <p>Making progress diversification, amplify, promising development participatory monitoring investment. </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-10 com-md-8 center-block">

                            <form className="form-inline domainsearch" method="post" action="#">
                                <div className="row no-gutter">
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" name="sld" placeholder="Enter your domain" />
                                    </div>
                                    <div className="col-sm-2">
                                        <select name="tld" className="form-control">
                                            <option>.com</option>
                                            <option>.net</option>
                                            <option>.org</option>
                                            <option>.eu</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }}>SEARCH</button>
                                    </div>

                                </div>
                            </form>


                            <div id="domainextensions">
                                <div className="item">
                                    <div className="extension">.com</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.net</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.rocks</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.org</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.ninja</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.co</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.haus</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.social</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.bio</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.house</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.deals</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.market</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.discount</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.fitness</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.furniture</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.tax</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.investments</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.fund</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.cash</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.actor</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.surgery</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.rip</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.forsale</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.com</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.coach</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.memorial</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.energy</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.band</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.com</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.legal</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.money</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.delivery</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.space</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.camera</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.app</div>
                                </div>

                                <div className="item">
                                    <div className="extension">.wow</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End of Domain Search --> */}




                <section className="domainfeatures white">

                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Domain Price List</h2>
                            <p>Billionaire philanthropy public sector rural development catalyst accessibility small-scale farmers hack. </p>
                        </div>
                    </div>

                    <div className="domains-table">
                        <div className="row">
                            <div className="col-sm-12">
                                <table data-wow-delay="0.3s" id="tld-table" className="tablesorter responsive wow fadeInUp tablesaw tablesaw-stack" data-tablesaw-mode="stack">
                                    <thead>
                                        <tr>
                                            <th>DOMAIN NAME</th>
                                            <th>REGISTRATION COST/YEAR</th>
                                            <th>RENEWAL COST/YEAR</th>
                                            <th>TRANSFER COST</th>
                                            <th>REGISTRATION PERIOD</th>
                                            <th>GRACE PERIOD</th>
                                            <th>REDEMPTION PERIOD</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>COM</td>
                                            <td>$12</td>
                                            <td>$12</td>
                                            <td>$12</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>NET</td>
                                            <td>$13</td>
                                            <td>$13</td>
                                            <td>$13</td>
                                            <td>1-5 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>ORG</td>
                                            <td>$14</td>
                                            <td>$14</td>
                                            <td>$14</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>BIZ</td>
                                            <td>$15</td>
                                            <td>$15</td>
                                            <td>$15</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>AERO</td>
                                            <td>$22</td>
                                            <td>$22</td>
                                            <td>$22</td>
                                            <td>1-2 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>AC</td>
                                            <td>$32</td>
                                            <td>$32</td>
                                            <td>$32</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>IO</td>
                                            <td>$42</td>
                                            <td>$42</td>
                                            <td>$42</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>CLUB</td>
                                            <td>$18</td>
                                            <td>$18</td>
                                            <td>$18</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>IS</td>
                                            <td>$52</td>
                                            <td>$52</td>
                                            <td>$52</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>US</td>
                                            <td>$9</td>
                                            <td>$9</td>
                                            <td>$9</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>EDU</td>
                                            <td>$12</td>
                                            <td>$12</td>
                                            <td>$12</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>ROCKS</td>
                                            <td>$29</td>
                                            <td>$29</td>
                                            <td>$29</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>FUND</td>
                                            <td>$42</td>
                                            <td>$42</td>
                                            <td>$42</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                        <tr>
                                            <td>CASH</td>
                                            <td>$42</td>
                                            <td>$42</td>
                                            <td>$42</td>
                                            <td>1-10 yrs</td>
                                            <td>15 days</td>
                                            <td>90 days</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="domainfeatures">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>A simple Domain Dashboard</h2>
                            <p>Healthcare committed, agenda conflict resolution, public service. Relief; crowdsourcing fundraise; accelerate progress metrics results. </p>
                        </div>
                    </div>

                    <div className="row spacing-70">
                        <div className="col-sm-4 wow fadeInUp" data-wow-delay="0.4s">
                            <h6>REGISTER</h6>
                            <p>Catalyst, Millennium Development Goals asylum working alongside Ford Foundation effectiveness institutions social analysis.</p>
                        </div>

                        <div className="col-sm-4 wow fadeInUp" data-wow-delay="0.6s">
                            <h6>TRANSFER</h6>
                            <p>Forward-thinking fighting poverty urban, respect climate change UNICEF public service progressive action informal economies.</p>
                        </div>

                        <div className="col-sm-4 wow fadeInUp" data-wow-delay="0.8s">
                            <h6>MANAGE</h6>
                            <p>Global citizens fight against malnutrition, tackle humanitarian relief, youth; diversification solutions fundraise natural.</p>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-sm-9 center-block wow fadeInUp" data-wow-delay="1.2s">
                            <img src="images/domains-img-1.png" alt="" className="img-responsive center-block spacing-70" />
                        </div>
                    </div>

                    <img src="images/separator.png" alt="" className="img-responsive center-block spacing-70" />

                    <div className="row  spacing-70">
                        <h2>Domain Names FAQ</h2>
                        <div className=" center-block wow fadeIn spacing-40" data-wow-delay="0.5s">
                            <div id="accordion" className="panel-group">

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapse1">What is the domain name system?</a></h4>
                                    </div>

                                    <div id="collapse1" className="panel-collapse collapse in">
                                        <div className="panel-body">
                                            <p>Marginalized communities momentum storytelling truth rural, crowdsourcing empower, sharing economy advancement, gender equality progressive celebrate micro-finance altruism. Medical, refugee enable generosity, measures turmoil theory of social change, UNHCR courageous growth care. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapse2">What happens if I don't renew my domains?</a></h4>
                                    </div>

                                    <div id="collapse2" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Forward-thinking prevention; contribution fundraising campaign research convener billionaire philanthropy human potential deep engagement. Emergency response expanding community ownership combat HIV/AIDS save the world Ford Foundation Aga Khan working families lifting people.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapse3">Can I register domain names if I don't have a hosting provider?</a></h4>
                                    </div>

                                    <div id="collapse3" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>World problem solving Cesar Chavez, fight against oppression, lasting change social analysis overcome injustice social entrepreneurship shifting landscape inspire breakthroughs effect peaceful immunize. Grantees underprivileged; life-saving, new approaches activism Andrew Carnegie design thinking Jane Jacobs sustainability treatment John Lennon. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapse4">What is a ccTLD?</a></h4>
                                    </div>

                                    <div id="collapse4" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Focus on impact relief scalable opportunity progressive public service collaborative expanding community ownership many voices community health workers complexity. Eradicate citizens of change necessities indicator.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapse5">My registrar is refusing to transfer my name. What do I do?</a></h4>
                                    </div>

                                    <div id="collapse5" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Meaningful work reproductive rights, planned giving network action economic security, Martin Luther King Jr. strengthen democracy social analysis civic engagement harness long-term affiliate agenda. Human-centered design, think tank.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapse6">Do you register all types of domains?</a></h4>
                                    </div>

                                    <div id="collapse6" className="panel-collapse collapse">
                                        <div className="panel-body">
                                            <p>Time of extraordinary change social microloans solutions honesty global health compassion change lives end hunger. Our ambitions lasting change women's rights, research implementation vulnerable population solution; Arab Spring generosity momentum catalyst benefit. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        sendMessage: (payload: any, callback: Function) =>
            dispatch(sendMessage(payload, callback)),
    };
}

export const DomainModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(DomainScreen)
);

export const Domain = AppLayout(DomainModule);
