import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import Utils from "../../../utils";
import { doLogin } from "../../auth";
import { Count } from "../home.action";
import { sendMessage } from "../home.action";
// import { Overlay } from "../../overlay/overlay";
// import '../../../assets/css/aos.css';
// import {getUserProfileData} from "../../edit-actor-profile/edit-actor-profile.action";

interface Props {
    t?: any;
    history?: any;
    sendMessage: Function;
    Count: Function;
    // getUserProfileData: Function;
}

interface State {

    AccountApprovalRequestsCount: string;
    CelebrityCount: string;
    FansCount: string;
    OrderApprovalRequestCount: string;
    AdminProfilesCount: string;
    RecommendationCount: string;
    PopularCount: string;
    name: string;
    nameColor: string;

    email: string;
    message: string;
    messageColor: string;

    subject: string;
    subjectColor: string;

    domain: string;

    phone: string;
    phoneColor: string;

    showLoginLoading: boolean;

    formSubmitted: string;
    emailColor: string;
    textArea: string;
    emailResponse: string;
    isWhitePaperRequest: boolean;
    error: {
        emailError: string;
        nameError: string;
        subjectError: string;
        messageError: string;
        phoneError: string;
    };


}
export class HomeScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            AccountApprovalRequestsCount: "",
            CelebrityCount: "",
            FansCount: "",
            OrderApprovalRequestCount: "",
            AdminProfilesCount: "",
            RecommendationCount: "",
            PopularCount: "",
            name: "",
            nameColor: "#FFFFFF",

            message: "",
            messageColor: "#FFFFFF",

            email: "",
            subject: "",
            subjectColor: "#FFFFFF",

            phone: "",
            phoneColor: "#FFFFFF",
            emailResponse: "",
            emailColor: "#FFFFFF",
            textArea: "",

            domain: "",

            formSubmitted: "0",
            isWhitePaperRequest: false,
            showLoginLoading: false,
            error: {
                emailError: "",
                nameError: "",
                subjectError: "",
                messageError: "",
                phoneError: ""
            },

        };
    }

    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        console.log('handled')

        let { email, message, name, subject, error } = this.state;

        let formError = Utils.constant.validateForm({ email, message, name, subject, error, });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");

        this.setState({ error: Object.assign({}, formError) }, () => {
            // Email
            if (this.state.error.emailError != "") {
                console.log(this.state.error)
                this.setState({ emailColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ emailColor: "#121212" })
            }
            // Name 
            if (this.state.error.nameError != "") {
                console.log(this.state.error)
                this.setState({ nameColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ nameColor: "#121212" })
            }
            // Message 
            if (this.state.error.messageError != "") {
                console.log(this.state.error)
                this.setState({ messageColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ messageColor: "#121212" })
            }
            // Subject 
            if (this.state.error.subjectError != "") {
                console.log(this.state.error)
                this.setState({ subjectColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ subjectColor: "#121212" })
            }
            // Phone
            if (this.state.error.phoneError != "") {
                console.log(this.state.error)
                this.setState({ phoneColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ phoneColor: "#121212" })
            }
        });
        if (!isValid) {
            return false;
        } else {

            this.setState({ showLoginLoading: true });

            const token = await localStorage.getItem("fcmToken");

            let payload = this.state.isWhitePaperRequest ? { email, message, name, subject, isWhitePaperRequest: "true" } : { email, message, subject, name, };

            this.props.sendMessage(payload, (response: any) => {
                console.log(response);
                console.log(response.message);
                if (response.message == "Account Approved Email Sent") {
                    this.setState({ formSubmitted: "1" })
                }
                else {
                    this.setState({ formSubmitted: "2" })
                }
                this.setState(
                    {
                        email: "",
                        message: "",
                        name: "",
                        subject: "",
                        phone: ""
                    },
                    () => {
                        this.setState({ emailResponse: "Sent" })

                        let element = document.getElementById("body-message");

                        if (element) {
                            element.classList.add("active");

                            let errorElement = document.getElementById("api-error-message");

                            if (errorElement) {
                                element.style.backgroundColor = "#00baee";
                                element.style.borderColor = "#00baee";
                                errorElement.innerHTML = "Sent";
                            }
                            setTimeout(function () {
                                if (element) {
                                    element.classList.remove("active");
                                }
                                if (errorElement) {
                                    errorElement.innerHTML = "";
                                }
                            }, 3000);
                        }

                    }
                );
            });
        }
    };
    count = async () => {
        console.log('All Counts Actors are here')
        this.props.Count({}, (response: any) => {
            console.log('back')
            console.log(response)
            this.setState({ AccountApprovalRequestsCount: response.Account_Approval_Request, CelebrityCount: response.Celebrities, FansCount: response.Fans, OrderApprovalRequestCount: response.Order_Approval_Request, AdminProfilesCount: response.Admin_Profile, PopularCount: response.Popular, RecommendationCount: response.Recommmend }, () => {

                //@ts-ignore
                //  window.SlikSlider();


            });
        });

    };

    // getAllActors = async (status: string) => {
    //     console.log('All Actors are here')
    //     this.props.GetAllActors({}, (response: any) => {
    //         console.log('back')
    //         console.log(response)
    //         this.setState({ actorsList: response }, ()=>{

    //             //@ts-ignore
    //             window.SlikSlider();


    //         });
    //     });

    // };


    async componentDidMount() {
        //     const script = document.createElement("script");
        // script.src = "assets/js/main.js";
        // script.async = true;
        // document.body.appendChild(script);
        //     const userToken = localStorage.getItem("accessToken");

        // if (userToken) {
        //     console.log('sending request')
        // }
        //     else {

        //         window.location.href = "/";
        //     }

        // // this.getAllActors('Seller')
        //     this.count()
        // const userToken = await localStorage.getItem("accessToken");

    }

    render() {
        return (
            <>
                
                {/* <div className="offcanvas-overlay"></div> */}
                {/* <!-- banner slider start --> */}
                <section className="banner-slider__wrapper pt-0 pb-0 overflow-hidden">
                    <div className="slider-controls">
                        <div className="banner-slider-arrows d-flex flex-column"></div>
                    </div>

                    <div className="banner-slider overflow-hidden" >
                        <div className="slider-item slider-item-after" style={{ backgroundImage: `url(assets/img/banner/banne-slider-1.png)` }}>
                            <div className="number" data-animation="fadeInUp" data-delay="0.6s">01</div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="banner__content text-center">
                                            <h6 className="sub-title color-white mb-15 mb-sm-15 mb-xs-10" data-animation="fadeInUp" data-delay="0.5s"><span>30 Years</span> Of Successful Business Consulting</h6>
                                            <h1 className="title color-white mb-sm-30 mb-xs-20 mb-40" data-animation="fadeInUp" data-delay="1s">A big Opportutnity for your Business Growth</h1>

                                            <div className="theme-btn__wrapper d-flex justify-content-center">
                                                <a href="/services" className="theme-btn btn-sm" data-animation="fadeInUp" data-delay="1.3s">Our Services <i className="fas fa-long-arrow-alt-right"></i></a>
                                                <a href="/aboutus" className="theme-btn btn-sm btn-white" data-animation="fadeInUp" data-delay="1.5s">Get A Quote <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item" style={{ backgroundImage: `url(assets/img/banner/banne-slider-1.png)` }}>
                            <div className="number" data-animation="fadeInUp" data-delay="0.6s">01</div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="banner__content text-center">
                                            <h6 className="sub-title color-white mb-15 mb-sm-15 mb-xs-10" data-animation="fadeInUp" data-delay="0.5s"><span>30 Years</span> Of Successful Business Consulting</h6>
                                            <h1 className="title color-white mb-sm-30 mb-xs-20 mb-40" data-animation="fadeInUp" data-delay="1s">A big Opportutnity for your Business Growth</h1>

                                            <div className="theme-btn__wrapper d-flex justify-content-center">
                                                <a href="/services" className="theme-btn btn-sm" data-animation="fadeInUp" data-delay="1.3s">Our Services <i className="fas fa-long-arrow-alt-right"></i></a>
                                                <a href="/aboutus" className="theme-btn btn-sm btn-white" data-animation="fadeInUp" data-delay="1.5s">Get A Quote <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item" style={{ backgroundImage: `url(assets/img/banner/banne-slider-1.png)` }}>
                            <div className="number" data-animation="fadeInUp" data-delay="0.6s">01</div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="banner__content text-center">
                                            <h6 className="sub-title color-white mb-15 mb-sm-15 mb-xs-10" data-animation="fadeInUp" data-delay="0.5s"><span>30 Years</span> Of Successful Business Consulting</h6>
                                            <h1 className="title color-white mb-sm-30 mb-xs-20 mb-40" data-animation="fadeInUp" data-delay="1s">A big Opportutnity for your Business Growth</h1>

                                            <div className="theme-btn__wrapper d-flex justify-content-center">
                                                <a href="/services" className="theme-btn btn-sm" data-animation="fadeInUp" data-delay="1.3s">Our Services <i className="fas fa-long-arrow-alt-right"></i></a>
                                                <a href="/aboutus" className="theme-btn btn-sm btn-white" data-animation="fadeInUp" data-delay="1.5s">Get A Quote <i className="fas fa-long-arrow-alt-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- banner slider end --> */}

                {/* <!-- about-us start --> */}
                <section className="about-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="about-us__content  mb-lg-60 mb-md-50 mb-sm-40 mb-xs-30">
                                    <span className="sub-title fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" /> About Us</span>
                                    <h2 className="title color-secondary mb-20 mb-sm-15 mb-xs-10">We Create a Culture That Inspires Us To Work Smart Together</h2>

                                    <div className="description font-la mb-50 mb-sm-40 mb-xs-30">
                                        <p>We envision a world where businesses thrive through innovation, strategic insights, and seamless operations. By combining expertise in HR, Fintech, customer, and technology services, we are committed to shaping a brighter future for our clients.</p>
                                    </div>

                                    <div className="circle-chart__wrapper d-flex flex-wrap justify-content-between mb-60 mb-md-50 mb-sm-40 mb-xs-30">
                                        <div className="circle-chart__item d-flex align-items-center">
                                            <div className="chart-wrapper">
                                                <div className="chart" data-percent="87" data-scale-color="#ffb400">87%</div>
                                            </div>
                                            <h6 className="title color-secondary">Digital Consultancy</h6>
                                        </div>

                                        <div className="circle-chart__item d-flex align-items-center">
                                            <div className="chart-wrapper">
                                                <div className="chart" data-percent="79" data-scale-color="#ffb400">79%</div>
                                            </div>
                                            <h6 className="title color-secondary">FinTech</h6>
                                        </div>
                                    </div>

                                    <a href="/aboutus" className="theme-btn  btn-yellow-transparent fw-600">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="about-us__media d-flex align-content-center justify-content-center align-items-center">
                                    <div className="media">
                                        <img src="assets/img/home-2/about-media.png" className="img-fluid" alt="" />
                                    </div>

                                    <div className="expert-team expert-team-one text-center" style={{ backgroundImage: `url(assets/img/home-2/expert-team-1.png)` }}>
                                        <div className="number color-white mb-10 mb-xs-5 fw-600"><span>100</span>+</div>
                                        <h6 className="title font-la color-white">Expert Team Members</h6>
                                    </div>

                                    <div className="expert-team expert-team-two text-center" style={{ backgroundImage: `url(assets/img/home-2/expert-team-2.png)` }}>
                                        <div className="number color-white mb-10 mb-xs-5 fw-600"><span>150</span>k</div>
                                        <h6 className="title font-la color-white">Clients Satisfaction Survey In Consulting Organization</h6>
                                    </div>

                                    <div className="expert-team expert-team-three text-center" style={{ backgroundImage: `url(assets/img/home-2/expert-team-3.png)` }}>
                                        <div className="number color-white mb-10 mb-xs-5 fw-600"><span>30</span>+</div>
                                        <h6 className="title font-la color-white">Years Experiance Our Company</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- about-us end --> */}

                {/* <!-- our-provide start --> */}
                <section className="similar-work services-work bg-dark_white pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-135 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="employee-friendly__content mb-65 mb-md-50 mb-sm-40 mb-xs-30 text-center">
                                    <span className="sub-title fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" /> Services</span>
                                    <h2 className="title color-d_black">Our Provide Specialized</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-minus-30">
                            <div className="col-lg-4 col-md-6">
                                <div className="similar-work__item mb-30 d-flex justify-content-between flex-column">
                                    <div className="top d-flex align-items-center">
                                        <div className="icon color-white text-center bg-yellow">
                                            <i className="fal fa-analytics"></i>
                                        </div>

                                        <h4 className="title color-secondary"><a href="/services">HR Services</a></h4>
                                    </div>

                                    <div className="bottom">
                                        <div className="media overflow-hidden">
                                            <img src="assets/img/services/services-4.png" className="img-fluid" alt="" />
                                        </div>

                                        <div className="text pt-30 pr-30 pb-30 pl-30 pt-sm-20 pt-xs-15 pr-sm-20 pr-xs-15 pb-sm-20 pb-xs-15 pl-sm-20 pl-xs-15 font-la">
                                            <p>Our HR Services are designed to streamline your human resources operations, allowing you to focus on growing your business.</p>
                                        </div>

                                        <a href="/services" className="theme-btn text-center fw-600 btn-yellow">Discover More <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="similar-work__item mb-30 d-flex justify-content-between flex-column">
                                    <div className="top d-flex align-items-center">
                                        <div className="icon color-white text-center bg-yellow">
                                            <i className="icon-planning"></i>
                                        </div>

                                        <h4 className="title color-secondary"><a href="/services">Fintech Services</a></h4>
                                    </div>

                                    <div className="bottom">
                                        <div className="media overflow-hidden">
                                            <img src="assets/img/services/services-5.png" className="img-fluid" alt="" />
                                        </div>

                                        <div className="text pt-30 pr-30 pb-30 pl-30 pt-sm-20 pt-xs-15 pr-sm-20 pr-xs-15 pb-sm-20 pb-xs-15 pl-sm-20 pl-xs-15 font-la">
                                            <p>In the fast-evolving world of finance and technology, our Fintech Services provide innovative solutions to help you stay ahead.</p>
                                        </div>

                                        <a href="/services" className="theme-btn text-center fw-600 btn-yellow">Discover More <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="similar-work__item mb-30 d-flex justify-content-between flex-column">
                                    <div className="top d-flex align-items-center">
                                        <div className="icon color-white text-center bg-yellow">
                                            <i className="icon-outline"></i>
                                        </div>

                                        <h4 className="title color-secondary"><a href="/services">Technology Services</a></h4>
                                    </div>

                                    <div className="bottom">
                                        <div className="media overflow-hidden">
                                            <img src="assets/img/services/services-6.png" className="img-fluid" alt="" />
                                        </div>

                                        <div className="text pt-30 pr-30 pb-30 pl-30 pt-sm-20 pt-xs-15 pr-sm-20 pr-xs-15 pb-sm-20 pb-xs-15 pl-sm-20 pl-xs-15 font-la">
                                            <p>Embrace the future with our Technology Services that cover a wide spectrum of IT solutions.</p>
                                        </div>

                                        <a href="/services" className="theme-btn text-center fw-600 btn-yellow">Discover More <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="theme-btn-wrapper mt-50 mt-md-40 mt-sm-30 mt-xs-25 text-center">
                                    <a href="/services" className="theme-btn btn-transparent fw-600"><i className="far fa-sync-alt"></i> More Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- our-provide end --> */}

                {/* <!-- why-choose start --> */}
                <section className="why-choose pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="why-choose__media-wrapper d-flex flex-column">
                                    <div className="gallery-bar bg-yellow"></div>

                                    <div className="why-choose__media">
                                        <img src="assets/img/services/why-choose-1.png" alt="" className="img-fluid" />
                                    </div>

                                    <div className="global-country text-center bg-yellow">
                                        <div className="number mb-5 color-white"><span className="counter">120</span>+</div>
                                        <h6 className="title color-white font-la">Global Country in <br />Our Company</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                                    <div className="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                                        <span className="sub-title d-block fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-lg-20 mb-25"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" /> Why choose us</span>
                                        <h2 className="title color-pd_black">Developing Solutions For The Future</h2>

                                        <div className="description font-la mt-20 mt-sm-15 mt-xs-10">
                                            <p>We take pride in delivering exceptional services that cater to the unique needs of our clients. With a team of seasoned professionals, we are committed to providing innovative, tailored solutions that drive growth and ensure sustainable success.</p>
                                        </div>
                                    </div>

                                    <div className="why-choose__item-wrapper d-grid justify-content-between">
                                        <div className="why-choose__item">
                                            <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                                <i className="icon-consultation"></i>
                                            </div>

                                            <h5 className="title color-secondary fw-500 mb-10">HR Services</h5>

                                            <div className="description font-la">
                                                <p>Our HR Services division is committed to optimizing your workforce potential.</p>
                                            </div>
                                        </div>

                                        <div className="why-choose__item">
                                            <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                                <i className="icon-lawyer"></i>
                                            </div>

                                            <h5 className="title color-secondary fw-500 mb-10">Fintech Services</h5>

                                            <div className="description font-la">
                                                <p>In the rapidly evolving fintech landscape, we stand at the forefront of innovation.</p>
                                            </div>
                                        </div>

                                        <div className="why-choose__item">
                                            <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                                <i className="icon-financial"></i>
                                            </div>

                                            <h5 className="title color-secondary fw-500 mb-10">Trusted Customer Support</h5>

                                            <div className="description font-la">
                                                <p> We ensure that your business operations run seamlessly, allowing you to focus on what you do best.</p>
                                            </div>
                                        </div>

                                        <div className="why-choose__item">
                                            <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                                <i className="icon-management"></i>
                                            </div>

                                            <h5 className="title color-d_black secondary-500 mb-10">Technology Services</h5>

                                            <div className="description font-la">
                                                <p>Embrace the future with our Technology Services that encompass software development, cloud integration, cybersecurity, and comprehensive IT consulting.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- why-choose end --> */}
                {/* <!-- counter-area start --> */}
                <div className="counter-area pb-xs-80 pb-sm-100 pb-md-100 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row mb-minus-30">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item counter-area__item-two d-flex align-items-center">
                                    <div className="icon color-yellow">
                                        <i className="icon-process-1"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-yellow"><span className="counter">5620</span>+</div>
                                        <div className="description font-la">Successful Project</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item counter-area__item-two d-flex align-items-center">
                                    <div className="icon color-yellow">
                                        <i className="icon-support-2"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-yellow"><span className="counter">150</span>+</div>
                                        <div className="description font-la">Expert Consultancy</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item counter-area__item-two d-flex align-items-center">
                                    <div className="icon color-yellow">
                                        <i className="icon-coffee-2"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-yellow"><span className="counter">96</span>+</div>
                                        <div className="description font-la">Cup Of Cofee</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="counter-area__item counter-area__item-two d-flex align-items-center">
                                    <div className="icon color-yellow">
                                        <i className="icon-teamwork-1"></i>
                                    </div>

                                    <div className="text text-center">
                                        <div className="number fw-600 color-yellow"><span className="counter">3225</span>+</div>
                                        <div className="description font-la">Client Satisfaction</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- counter-area end --> */}
                {/* <!-- our-porfolio start --> */}
                {/* <section className="our-team our-porfolio pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-135 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="our-team__content mb-65 mb-md-50 mb-sm-40 mb-xs-30 text-center mx-auto">
                                    <span className="sub-title fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" /> Portfolio</span>
                                    <h2 className="title color-d_black">We've Done Lot’s Of Awesome Case Study</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="our-porfolio__slider">
                        <div className="slider-item">
                            <div className="our-project__item overflow-hidden">
                                <img src="assets/img/portfolio/portfolio-1.png" alt="" />

                                <div className="content d-flex align-items-center justify-content-between">
                                    <div className="text">
                                        <span className="fw-500 color-yellow d-block mb-10 text-uppercase">Financial solution</span>
                                        <h5 className="title color-secondary">Financial Reporting</h5>
                                    </div>

                                    <a href="our-project.html" className="theme-btn"><i className="icon-arrow-right-2"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item">
                            <div className="our-project__item overflow-hidden">
                                <img src="assets/img/portfolio/portfolio-2.png" alt="" />

                                <div className="content d-flex align-items-center justify-content-between">
                                    <div className="text">
                                        <span className="fw-500 color-yellow d-block mb-10 text-uppercase">Business, Finance</span>
                                        <h5 className="title color-secondary">Business & Strategy</h5>
                                    </div>

                                    <a href="our-project.html" className="theme-btn"><i className="icon-arrow-right-2"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item">
                            <div className="our-project__item overflow-hidden">
                                <img src="assets/img/portfolio/portfolio-3.png" alt="" />

                                <div className="content d-flex align-items-center justify-content-between">
                                    <div className="text">
                                        <span className="fw-500 color-yellow d-block mb-10 text-uppercase">Business, Consulting</span>
                                        <h5 className="title color-secondary">Business Consulting</h5>
                                    </div>

                                    <a href="our-project.html" className="theme-btn"><i className="icon-arrow-right-2"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item">
                            <div className="our-project__item overflow-hidden">
                                <img src="assets/img/portfolio/portfolio-1.png" alt="" />

                                <div className="content d-flex align-items-center justify-content-between">
                                    <div className="text">
                                        <span className="fw-500 color-yellow d-block mb-10 text-uppercase">Financial solution</span>
                                        <h5 className="title color-secondary">Financial Reporting</h5>
                                    </div>

                                    <a href="our-project.html" className="theme-btn"><i className="icon-arrow-right-2"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item">
                            <div className="our-project__item overflow-hidden">
                                <img src="assets/img/portfolio/portfolio-2.png" alt="" />

                                <div className="content d-flex align-items-center justify-content-between">
                                    <div className="text">
                                        <span className="fw-500 color-yellow d-block mb-10 text-uppercase">Business, Finance</span>
                                        <h5 className="title color-secondary">Business & Strategy</h5>
                                    </div>

                                    <a href="our-project.html" className="theme-btn"><i className="icon-arrow-right-2"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item">
                            <div className="our-project__item overflow-hidden">
                                <img src="assets/img/portfolio/portfolio-3.png" alt="" />

                                <div className="content d-flex align-items-center justify-content-between">
                                    <div className="text">
                                        <span className="fw-500 color-yellow d-block mb-10 text-uppercase">Business, Consulting</span>
                                        <h5 className="title color-secondary">Business Consulting</h5>
                                    </div>

                                    <a href="our-project.html" className="theme-btn"><i className="icon-arrow-right-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <!-- our-porfolio end --> */}

                {/* <!-- work-process end --> */}
                <section className="work-process pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-100 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="pricing__content mb-60 mb-sm-40 mb-xs-30 text-center">
                                    <span className="sub-title d-block fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-lg-20 mb-25"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" /> Our Work Process</span>
                                    <h2 className="title color-d_black">How Our Services Work</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-minus-30">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="work-process__item mb-70 text-center">
                                    <div className="icon mx-auto">
                                        <i className="icon-research"></i>
                                    </div>

                                    <div className="text">
                                        <h6 className="title color-secondary mb-15 mb-sm-10 mb-xs-5">Research Your Problem</h6>

                                        <div className="description font-la">
                                            <p>Discovering the root of your challenges is the first step towards finding effective solutions.</p>
                                        </div>
                                    </div>

                                    <button className="theme-btn btn-black text-uppercase">Step - 1</button>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="work-process__item mb-70 text-center">
                                    <div className="icon mx-auto">
                                        <i className="icon-worker-1"></i>
                                    </div>

                                    <div className="text">
                                        <h6 className="title color-secondary mb-15 mb-sm-10 mb-xs-5">Identifying Problem</h6>

                                        <div className="description font-la">
                                            <p>Understanding your business intricacies and pain points is crucial for formulating targeted solutions.</p>
                                        </div>
                                    </div>

                                    <button className="theme-btn btn-black text-uppercase">Step - 2</button>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="work-process__item mb-70 text-center">
                                    <div className="icon mx-auto">
                                        <i className="icon-outline"></i>
                                    </div>

                                    <div className="text">
                                        <h6 className="title color-secondary mb-15 mb-sm-10 mb-xs-5">Solutions Your Problem</h6>

                                        <div className="description font-la">
                                            <p>With a clear understanding of the issues at hand, we craft tailored solutions that address your specific needs.</p>
                                        </div>
                                    </div>

                                    <button className="theme-btn btn-black text-uppercase">Step - 3</button>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="work-process__item mb-70 text-center">
                                    <div className="icon mx-auto">
                                        <i className="icon-target-2"></i>
                                    </div>

                                    <div className="text">
                                        <h6 className="title color-secondary mb-15 mb-sm-10 mb-xs-5">Success Your Business</h6>

                                        <div className="description font-la">
                                            <p>Seeing your business thrive is our ultimate goal. We work alongside you to implement the solutions, ensuring they align seamlessly with your operations.</p>
                                        </div>
                                    </div>

                                    <button className="theme-btn btn-black text-uppercase">Step - 4</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- work-process end --> */}
            </>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        // GetAllActors: (payload: any, callback: Function) =>
        //     dispatch(GetAllActors(payload, callback)),
        doLogin: (payload: any, callback: Function) =>
            dispatch(doLogin(payload, callback)),
        Count: (payload: any, callback: Function) =>
            dispatch(Count(payload, callback)),
        sendMessage: (payload: any, callback: Function) =>
            dispatch(sendMessage(payload, callback)),
    }
}


export const HomeModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
);
//AppLayout(HomeModule)
export const Home = AppLayout(HomeModule);
