import React, { PureComponent, Component } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";
import Authlayout from "../../../applayout/authlayout";
import Utils from "../../../utils";
import { NavLink } from "react-router-dom";
// import { doLogin, doSocialmediaLogin } from "../";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
// import GoogleLogin from "react-google-login";
//@ts-ignore
// import FacebookLogin from "react-facebook-login";
import { toast } from "react-toastify";
// import firebase from "../../../utils/firebase";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  t: any;
  history?: any;
  doLogin: Function;
  loginResponce?: any;
  doSocialmediaLogin: Function;
}
interface State {
  email: string;
  password: string;
  autoLoad: boolean;
  loginError: boolean;
  passwordIcon: boolean;
  showLoginLoading: boolean;
  error: {
    emailError: string;
    passwordError: string;
  };
}
class LoginScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      autoLoad: false,
      loginError: false,
      passwordIcon: true,
      showLoginLoading: false,
      error: {
        emailError: "",
        passwordError: "",
      },
    };
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };
  componentDidMount() {
    // if (Utils.firebase.messaging.isSupported()) {
    //   const fcm_token = Utils.firebase.messaging();
    //   fcm_token
    //     .requestPermission()
    //     .then((token) => {
    //       return fcm_token.getToken();
    //     })
    //     .then((save_token) => {
    //       localStorage.setItem("fcmToken", save_token);
    //     })
    //     .catch((err) => {});
    // }
    navigator.geolocation.getCurrentPosition(function (position) {
      localStorage.setItem("latitude", String(position.coords.latitude));
      localStorage.setItem("longitude", String(position.coords.longitude));
    });
  }

  handleSubmit = async () => {
    let { email, password, error } = this.state;
    let formError = Utils.constant.validateForm({ email, password, error });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {});
    if (!isValid) {
      return false;
    } else {
      this.setState({ showLoginLoading: true });
      const token = await localStorage.getItem("fcmToken");
      let payload = {
        email,
        password,
        device_token: token,
        device_type: "3",
        user_type: Utils.constant.userType.recruiter,
        device_id: "device123",
        fcm_token: token,
      };
      this.props.doLogin(payload, (response: any) => {
        if (!response.loginerror && response.user_id) {
        //   const database = firebase.database();
        // const database = sql.database();
        //   const userRef = database.ref("users");
          const userDetals = {
            device_token: localStorage.getItem("deviceToken") || "",
            device_type: "3",
            email: this.state.email,
            first_name: response.first_name,
            image: "",
            isOnline: false,
            last_name: response.last_name,
            mobile: "",
            user_id: response.user_id,
          };
          this.setState(
                  { email: "", password: "", showLoginLoading: false },
                  () => {
                    this.props.history.push("home");
                  }
                );
        } else {
          this.setState({
            showLoginLoading: false,
          });
        }
      });
    }
  };
  responseGoogle = async (response: any) => {
    const fcmToken = (await localStorage.getItem("fcmToken")) || "";
    if (response) {
      let payload = {
        socialId: response.googleId,
        device_token: fcmToken,
        device_type: "3",
        user_type: "2",
        fcm_token: fcmToken,
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
      };
      this.props.doSocialmediaLogin(payload, (response: any) => {
        this.setState({ email: "", password: "" });
        let page = response
          ? response.company
            ? response.company.isOtpVerified === "1"
            : null
          : null;
        if (page) {
          this.props.history.push("/home");
        } else {
          this.props.history.push("/recruiter");
        }
      });
    }
  };
  responseGoogleError = (response: any) => {
    // alert("oops some thing went wrong, please try again");
    {
      this.setState({
        loginError: true,
      });
    }
  };
  name = (name: any) => {
    let nameArray = name.split(" ");
    return nameArray;
  };
  responseFacebook = async (response: any) => {
    if (response.name && response.email) {
      let name = this.name(response.name);
      const fcmToken = await localStorage.getItem("fcmToken");
      if (response && name) {
        let payload = {
          socialId: response.id,
          device_token: fcmToken ? fcmToken : "",
          device_type: "3",
          user_type: "2",
          fcm_token: fcmToken ? fcmToken : "",
          email: response.email,
          first_name: name[0],
          last_name: name[1],
        };
        this.props.doSocialmediaLogin(payload, (response: any) => {
          this.setState({ email: "", password: "" });
          let page = response
            ? response.company
              ? response.company.isOtpVerified === "1"
              : null
            : null;
          if (page) {
            this.props.history.push("/home");
          } else {
            this.props.history.push("/recruiter");
          }
        });
      }
    } else {
      this.setState({
        loginError: true,
      });
    }
  };
  facebook = () => {
    this.setState({
      autoLoad: !this.state.autoLoad,
    });
  };
  selectLang = (language: string) => {
    // Utils.constant.axios.defaults.headers.lng = language;
    // i18n.changeLanguage(language);
  };
  handleSocialLogin = (user: any) => {};

  handleSocialLoginFailure = (err: any) => {
    console.error(err);
  };
  render() {
    const { t } = this.props;
    if (this.state.error) {
      toast("oops something went wrong !");
    }
    return (
      <div className="custom_container_auth">
        <OnBoardingBanner pageName={t("signin")} />
        <div className="onboarding_col_right">
          <section className="auth_header_main">
            <nav>
              <ul>
                <li className="option_one">
                  <h3
                    className={`lang_option ${
                      i18n.language === "nl" ? "applied" : ""
                    }`}
                    id="du"
                    onClick={() => this.selectLang("nl")}
                  >
                    NL
                  </h3>
                </li>
                <li>
                  <h3
                    className={`lang_option border_left ${
                      i18n.language.includes("en") ? "applied" : ""
                    }`}
                    id="en"
                    onClick={() => this.selectLang("en")}
                  >
                    EN
                  </h3>
                </li>
                <li>
                  <NavLink to="/signup">
                    <button className="signup_btn">{t("signup")}</button>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </section>
          <section className="inner_wrapper_auth">
            <div className="inner_Wrapper_form_wrapper">
              <div className="onboard_heading_wrapper">
                <h1>{t("welcometograbjobs")}</h1>
                <p>{t("signinwitheemail")}</p>
              </div>

              <div className="auth_form_wrapper">
                <label htmlFor="email">{t("email")}</label>
                <input
                  type="email"
                  placeholder="user@mail.com"
                  value={this.state.email}
                  id="email"
                  name="email"
                  onChange={(e: any) => {
                    this.handleFiledUpdate("email", e.currentTarget.value);
                  }}
                />
                <span className="error_mssg">
                  {this.state.error.emailError}
                </span>
              </div>

              <div className="auth_form_wrapper password_container">
                <label htmlFor="password">{t("password")}</label>
                <input
                  type="password"
                  placeholder="******"
                  id="password"
                  value={this.state.password}
                  onChange={(e: any) => {
                    this.handleFiledUpdate("password", e.currentTarget.value);
                  }}
                  onKeyDown={this.handleKeyDown}
                />
                {/* <img
                  src={
                    this.state.passwordIcon
                      ? require("../../../assets/images/password_show.svg")
                      : require("../../../assets/images/password_hide.svg")
                  }
                  onClick={() => {
                    const passwordInput: any = document.querySelector(
                      ".password_container #password"
                    );
                    if (passwordInput) {
                      const type =
                        passwordInput.getAttribute("type") === "password"
                          ? "text"
                          : "password";
                      passwordInput.setAttribute("type", type);
                      this.setState({
                        passwordIcon: !this.state.passwordIcon,
                      });
                    }
                  }}
                  alt="password lock"
                /> */}
                <span className="error_mssg">
                  {this.state.error.passwordError}
                </span>
              </div>

              <div className="auth_form_wrapper">
                <div className="text_field_wrap">
                  <NavLink to="/forgot" className="forgot_password">
                    {t("forgotPassTitle")}
                  </NavLink>
                </div>
              </div>

              <div className="auth_form_wrapper">
                <button className="btn_main" onClick={this.handleSubmit}>
                  {!this.state.showLoginLoading ? (
                    t("sign_in")
                  ) : (
                    <span className="loader three-dots"></span>
                  )}
                </button>
              </div>
              <div className="auth_form_wrapper text-center">
                <span className="or_continue_with">{t("orcontinue")}</span>
              </div>

              <div className="auth_form_wrapper social_btn_container">
            <div className="g_facebook_btn">  
            {/* <FacebookLogin
                  appId={process.env.REACT_FACEBOOK_APPID || "506087726976639"}
                  autoLoad={this.state.autoLoad}
                  fields="name,email,picture"
                  callback={this.responseFacebook}
                  cssClass="social_btn facebook_btn"
                  icon="fa-facebook"
                  textButton="Facebook"
                /> */}
                </div>  
                 <div className="g_google_btn"> 
                {/* <GoogleLogin
                  clientId={
                    process.env.REACT_GOOGLE_CLIENTID ||
                    "989600495713-ks2erjb4pm9q8s2b8vt5rat0dn9c0sgc.apps.googleusercontent.com"
                  }
                  buttonText="Login"
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      className="social_btn google_btn"
                    >
                      <span className="ic_google"> </span>
                      Google
                    </button>
                  )}
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogleError}
                  cookiePolicy={"single_host_origin"}
                  className="fb_btn color-red"
                /> */}
                </div> 
              </div>
              <div className="auth_form_wrapper text-center redirectOption">
                <span>{t("donthaveanaccount")}?</span>
                <NavLink to="/signup">
                  <span>{t("signup")}</span>
                </NavLink>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    // doLogin: (payload: any, callback: Function) =>
    //   dispatch(doLogin(payload, callback)),
    // doSocialmediaLogin: (payload: any, callback: Function) =>
    //   dispatch(doSocialmediaLogin(payload, callback)),
  };
}

const LoginModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
);

export const Login = Authlayout(LoginModule);
