import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
    t: any;
    sendMessage: Function;
    loginResponce?: any;

}
interface State {
    email: string;
    showLoginLoading: boolean;
    emailColor: string;
    textArea: string;
    emailResponse: string;
    isWhitePaperRequest: boolean;
    error: {
        emailError: string;
    };
}

export class CloudScreen extends PureComponent<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            emailResponse: "",
            emailColor: "#FFFFFF",
            textArea: "",
            isWhitePaperRequest: false,
            showLoginLoading: false,
            error: {
                emailError: "",
            },
        };
    }

    componentDidMount() {
        console.log(window.location.search);

        if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
            this.setState({ isWhitePaperRequest: true });
        }
    }
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        console.log('handled')

        let { email, textArea, error } = this.state;

        let formError = Utils.constant.validateForm({ email, textArea, error });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");

        this.setState({ error: Object.assign({}, formError) }, () => {
            if (this.state.error.emailError != "") {
                console.log(this.state.error)
                this.setState({ emailColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ emailColor: "#121212" })
            }

        });
        if (!isValid) {
            return false;
        } else {

            this.setState({ showLoginLoading: true });

            const token = await localStorage.getItem("fcmToken");

            let payload = this.state.isWhitePaperRequest ? { email, textArea, isWhitePaperRequest: "true" } : { email, textArea };

            this.props.sendMessage(payload, (response: any) => {
                this.setState(
                    {
                        email: "",
                        textArea: ""
                    },
                    () => {
                        this.setState({ emailResponse: "Sent" })

                        let element = document.getElementById("body-message");

                        if (element) {
                            element.classList.add("active");

                            let errorElement = document.getElementById("api-error-message");

                            if (errorElement) {
                                element.style.backgroundColor = "#00baee";
                                element.style.borderColor = "#00baee";
                                errorElement.innerHTML = "Sent";
                            }
                            setTimeout(function () {
                                if (element) {
                                    element.classList.remove("active");
                                }
                                if (errorElement) {
                                    errorElement.innerHTML = "";
                                }
                            }, 3000);
                        }

                    }
                );
            });
        }
    };

    // reset=( )=>{

    //   this.setState({

    //       email: "",
    //       emailResponse: "",
    //       emailColor: "#FFFFFF",
    //       textArea: "",
    //       isWhitePaperRequest:false,
    //       showLoginLoading: false,
    //       error: {
    //       emailError: "",
    //      },
    //   })

    // };

    render() {

        return (
            <>
                {/* <!-- Breadcrumps --> */}
    <div className="breadcrumbs">
        <div className="row">
            <div className="col-sm-6">
                <h1>Cloud VPS</h1>
            </div>
            <div className="col-sm-6">
                <ol className="breadcrumb">
                    <li>You are here: </li>
                    <li><a href="index-2.html">Home</a>
                    </li>
                    <li><a href="#">Hosting</a>
                    </li>
                    <li className="active">Cloud VPS</li>
                </ol>
            </div>
        </div>
    </div>
    {/* <!-- End of Breadcrumps --> */}

    <section className="pricingtables vps">
        <div className="row">
            <div className="col-sm-12">
                <h2>Short provisioning time and easy resource allocation</h2>
                <p>Billionaire philanthropy public sector rural development catalyst accessibility small-scale farmers hack. </p>
            </div>
        </div>
    </section>

    {/* <!-- VPS Prices Slider --> */}

    <div className="vps-order">

        {/* <!-- VPS ORDER STEPS --> */}
        <div className="row">
            <div data-wow-delay="0.2s" className="col-sm-4 wow zoomIn hidden-xs">
                <img src="images/vps_step_1.png" className="img-responsive center-block" alt="" />
            </div>

            <div data-wow-delay="0.4s" className="col-sm-4 wow zoomIn hidden-xs">
                <img src="images/vps_step_2.png" className="img-responsive center-block" alt="" />
            </div>

            <div data-wow-delay="0.6s" className="col-sm-4 wow zoomIn hidden-xs">
                <img src="images/vps_step_3.png" className="img-responsive center-block" alt="" />
            </div>

            <div className="col-sm-12 hidden-xs">

                <div className="order-step">
                    <div className="row no-gutter">

                        <div data-wow-delay="0.2s" className="order-circle col-sm-4 wow fadeInUp">
                            <span className="left-side">1</span>
                        </div>

                        <div data-wow-delay="0.4s" className="order-circle col-sm-4 wow fadeInUp">
                            <span>2</span>
                        </div>

                        <div data-wow-delay="0.6s" className="order-circle col-sm-4 wow fadeInUp">
                            <span className="right-side">3</span>
                        </div>

                    </div>
                </div>
            </div>


            <div className="col-sm-4">
                <img className="visible-xs img-responsive center-block smscrimg" src="images/vps_step_1.png" alt="" />
                <h3>Choose a Cloud VPS Plan</h3>
                <p>Plumpy'nut meaningful fundraising campaign prosperity emergency response liberal. </p>
            </div>

            <div className="col-sm-4">
                <img className="visible-xs img-responsive center-block smscrimg" src="images/vps_step_2.png" alt="" />
                <h3>Create Your Account</h3>
                <p> Local, peace informal economies youth committed free-speech vaccines evolution.</p>
            </div>

            <div className="col-sm-4">
                <img className="visible-xs img-responsive center-block smscrimg" src="images/vps_step_3.png" alt="" />
                <h3>Launch your VPS</h3>
                <p>Overcome injustice advancement process; breakthrough insights compassion progress.</p>
            </div>

        </div>

        {/* <!--  END OF ORDER STEPS  --> */}


        <div className="row">
            <div className="col-sm-12">

                <div className="vps-prices-container">
                    <div className="vps-prices-panel">

                        <div className="vps-prices-drag">

                            <div id="vps-slider"></div>
                            <div id="sliderlines"></div>

                            <div className="vps-plans threeplans hidden-xs">
                                <div className="vps-plan" id="vps-plan-0">VPS 1</div>
                                <div className="vps-plan" id="vps-plan-1">VPS 2</div>
                                <div className="vps-plan" id="vps-plan-2">VPS 3</div>
                            </div>

                            <div id="plan_option" className="visible-xs">
                                    <h6><span className="how_much"></span></h6>
                                </div>
                        </div>

                        <div className="row sliderbottom no-gutter">

                            <div className="col-sm-3">
                                <div id="disk_space_option">
                                    <h6>Disk Space</h6>
                                    <span className="how_much"></span>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div id="memory_option">
                                    <h6>RAM</h6>
                                    <span className="how_much"></span>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div id="bandwidth_option">
                                    <h6>Bandwidth</h6>
                                    <span className="how_much"></span>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="total_amount">$<span id="price_amount">39</span><span id="decimal">.00</span>
                                </div>
                                <a className="order-vps" href="#">ORDER VPS</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </div>


    {/* <!--  Pricing Tables --> */}
    <section className="pricingtables vps">
        <div className="row">
            <div className="col-sm-12">
                <h2>Details per VPS plan</h2>
                <p>Opportunity organization think tank, non-partisan fundraising campaign social responsibility</p>
            </div>
        </div>

        <div className="row spacing-70">

            <div className="col-sm-4 wow zoomIn" data-wow-delay="0.2s">
                <div className="panel panel-info">
                    <div className="panel-heading">
                        <h3 className="text-center">VPS 1</h3>
                    </div>
                    <div className="panel-body text-center">
                        <h4>$39</h4>
                        <span className="per">PER MONTH</span>
                    </div>
                    <ul className="text-center">
                        <li>Personal use</li>
                        <li>Unlimited projects</li>
                        <li>24/7 support</li>
                        <li>Personal use</li>
                        <li>Unlimited projects</li>
                        <li>24/7 support</li>
                    </ul>
                    <div className="panel-footer">
                        <a className="btn btn-lg btn-pricetable" href="#">ORDER NOW</a>
                    </div>
                </div>
            </div>

            <div className="col-sm-4 wow zoomIn" data-wow-delay="0.4s">
                <div className="panel panel-info">
                    <div className="panel-heading">
                        <h3 className="text-center">VPS 2</h3>
                    </div>
                    <div className="panel-body text-center">
                        <h4>$79</h4>
                        <span className="per">PER MONTH</span>
                    </div>
                    <ul className="text-center">
                        <li>Personal use</li>
                        <li>Unlimited projects</li>
                        <li>24/7 support</li>
                        <li>Personal use</li>
                        <li>Unlimited projects</li>
                        <li>24/7 support</li>
                    </ul>
                    <div className="panel-footer">
                        <a className="btn btn-lg btn-pricetable" href="#">ORDER NOW</a>
                    </div>
                </div>
            </div>

        <div className="col-sm-4 wow zoomIn" data-wow-delay="0.8s">
                <div className="panel panel-info">
                    <div className="panel-heading">
                        <h3 className="text-center">VPS 4</h3>
                    </div>
                    <div className="panel-body text-center">
                        <h4>$139</h4>
                        <span className="per">PER MONTH</span>
                    </div>
                    <ul className="text-center">
                        <li>Personal use</li>
                        <li>Unlimited projects</li>
                        <li>24/7 support</li>
                        <li>Personal use</li>
                        <li>Unlimited projects</li>
                        <li>24/7 support</li>
                    </ul>
                    <div className="panel-footer">
                        <a className="btn btn-lg btn-pricetable" href="#">ORDER NOW</a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    {/* <!-- End of Pricing Tables --> */}


{/* <!--  Features --> */}
    <section className="features">
        <div className="row">
            <div className="col-sm-12">
                <h2>Scalability. Control. Performance</h2>
                <span>Philanthropy think tank, approach Martin Luther King Jr., agency</span>
            </div>
        </div>

        <div className="row spacing-70">
            <div className="col-sm-3">
                <div className="feature wow zoomIn" data-wow-delay="0.2s">
                    <i className="fa fa-sellsy"></i>
                    <h4>Fast & simple</h4>
                    <p>Sustainable, liberal facilitate change movements outcomes Millennium Development Goals.</p>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="feature wow zoomIn" data-wow-delay="0.4s">
                    <i className="fa fa-server"></i>
                    <h4>Control Panel</h4>
                    <p>John Lennon, involvement Gandhi, working alongside cornerstone. Arab Spring; honesty save lives think.</p>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="feature wow zoomIn" data-wow-delay="0.6s">
                    <i className="fa fa-hdd-o"></i>
                    <h4>SSD storage</h4>
                    <p>Respond our grantees and partners, youth, fight against malnutrition experience in the field detection.</p>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="feature wow zoomIn" data-wow-delay="0.8s">
                    <i className="fa fa-life-ring"></i>
                    <h4>24/7 support</h4>
                    <p>Respond our grantees and partners, youth, fight against malnutrition experience in the field detection.</p>
                </div>
            </div>

        </div>
    </section>
    {/* <!-- End of Features --> */}
            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        sendMessage: (payload: any, callback: Function) =>
            dispatch(sendMessage(payload, callback)),
    };
}

export const CloudModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(CloudScreen)
);

export const Cloud = AppLayout(CloudModule);
