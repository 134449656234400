import { combineReducers } from "redux";
import { authReducer } from "../modules/auth/auth.reducer";
import { homeReducer } from "../modules/home/home.reducer";
// import { SelectCategoryReducer, CreateNFTReducer, UpdateNFTReducer } from "../modules/create-single/create-single.reducer";
// import { userProfileReducer } from "../modules/actor-profile/actor-profile.reducer";


/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  authReducer,
  homeReducer,
  // userProfileReducer
});

const appReducer = (state: any, action: any) => {
  return rootReducer(state, action);
};

export default appReducer;
