import React, { useState, memo } from "react";
import { LogoutConfirmationModal } from "../confirmation-modal/confirmationmodal";


import { doLogin } from "../../modules/auth/auth.action";
import { doSignup } from "../../modules/auth/auth.action";
import { Link } from "react-router-dom";


interface Props {
    changeLanguage?: any;

}
export default memo(function Onboardingheader(props: Props) {

    const [logoutConfirmation, setLogoutConfirmation] = useState(false),
        [urole, setURole] = useState(""),
        //{ t, i18n } = useTranslation(),
        userName = localStorage.getItem("username"),
        recruiterPic = localStorage.getItem("recruiterPic"),
        userid = localStorage.getItem("userId");
    var role = localStorage.getItem("role");
    localStorage.setItem("languageSelected", 'en');


    async function handleUserState(role: string) {
        console.log(' state handled')
        window.localStorage.setItem("role", role)
        // window.location.href="/signup"
        setURole(role)

    };






    function handleLogout() { localStorage.clear(); window.location.href = '/home' }
    function checkFunctionality() {
        var protocol = 'https';
        var userid = localStorage.getItem("userId");


        var page = window.location;
        if (page.href.includes('https')) { protocol = 'https' }
        else if (page.href.includes('http')) { protocol = 'http'; }
        console.log(page)

    }
    return (
        <>
            {/* <!-- preloader --> */}
            {/* <div id="preloader" className="preloader">
                <div className="animation-preloader">
                    <div className="spinner">
                    </div>
                    <div className="txt-loading">
                        <span data-text-preloader="C" className="letters-loading">
                            C
                        </span>
                        <span data-text-preloader="O" className="letters-loading">
                            O
                        </span>
                        <span data-text-preloader="N" className="letters-loading">
                            N
                        </span>
                        <span data-text-preloader="S" className="letters-loading">
                            S
                        </span>
                        <span data-text-preloader="U" className="letters-loading">
                            U
                        </span>
                        <span data-text-preloader="L" className="letters-loading">
                            L
                        </span>
                        <span data-text-preloader="T" className="letters-loading">
                            T
                        </span>
                        <span data-text-preloader="E" className="letters-loading">
                            E
                        </span>
                        <span data-text-preloader="R" className="letters-loading">
                            R
                        </span>
                    </div>
                    <p className="text-center">Loading</p>
                </div>
                <div className="loader">
                    <div className="row">
                        <div className="col-3 loader-section section-left">
                            <div className="bg"></div>
                        </div>
                        <div className="col-3 loader-section section-left">
                            <div className="bg"></div>
                        </div>
                        <div className="col-3 loader-section section-right">
                            <div className="bg"></div>
                        </div>
                        <div className="col-3 loader-section section-right">
                            <div className="bg"></div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <!-- welcome content start from here --> */}

            {/* <!-- header end --> */}
            <header className="header header-1 transparent header-2">
                <div className="top-header d-none d-xl-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="header-logo">
                                    <div className="logo">
                                        <a href="/">
                                            <img src="assets/img/logo_r.png" alt="logo" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                {/* <div className="header-right-socail d-flex justify-content-end align-items-center">
                                    <a className="header-contact d-none d-md-flex align-items-center">
                                        <div className="icon color-yellow">
                                            <i className="icon-phone"></i>
                                        </div>
                                        <div className="text">
                                            <span className="mb-2 d-block fw-normal color-white">Call Us Today</span>
                                            <h5 className="fw-600 color-white">051-2755701</h5>
                                        </div>
                                    </a>

                                    <a href="mailto:consulter@gmail.com" className="header-contact d-none d-md-flex align-items-center">
                                        <div className="icon color-yellow">
                                            <i className="icon-email"></i>
                                        </div>
                                        <div className="text">
                                            <span className="mb-2 d-block fw-normal color-white">E-mail Us</span>
                                            <h5 className="fw-600 color-white">support@metalinkbpo.com</h5>
                                        </div>
                                    </a>

                                    <a className="header-contact d-none d-md-flex align-items-center">
                                        <div className="icon color-yellow">
                                            <i className="fal fa-map-marker-alt"></i>
                                        </div>
                                        <div className="text">
                                            <span className="mb-2 d-block fw-normal color-white">Our Address</span>
                                            <h5 className="fw-600 color-white">Saddar, RWP</h5>
                                        </div>
                                    </a>

                                    <a className="header-contact d-none d-md-flex align-items-center">
                                        <div className="icon color-yellow">
                                            <i className="fal fa-clock"></i>
                                        </div>
                                        <div className="text">
                                            <span className="mb-2 d-block fw-normal color-white">Open Every Day</span>
                                            <h5 className="fw-600 color-white">10am : 10pm</h5>
                                        </div>
                                    </a>
                                </div>                             */}
                                <div className="header-menu d-none d-xl-block">
                                        <div className="main-menu">
                                            <ul>
                                                <li>
                                                    <a href="/">Home</a>
                                                    {/* <ul>
                                                        <li><a href="index.html">home 1</a></li>
                                                        <li><a href="index-2.html">home 2</a></li>
                                                        <li><a href="index-3.html">home 3</a></li>
                                                        <li><a href="index-4.html">home 4</a></li>
                                                        <li><a href="index-5.html">home 5</a></li>
                                                    </ul> */}
                                                </li>
                                                <li>
                                                    <a href="/services">Our Services</a>
                                                    {/* <Link to="/services"></Link> */}

                                                    {/* <ul>
                                                        <li><a href="services.html">Our Services 1</a></li>
                                                        <li><a href="services-2.html">Our Services 2</a></li>
                                                    </ul> */}
                                                </li>
                                                <li>
                                                    <a href="/aboutus">About Us</a>

                                                    {/* <ul>
                                                        <li><a href="about.html">About</a></li>
                                                        <li><a href="contact.html">Contact</a></li>
                                                        <li><a href="table.html">Pricing Table</a></li>
                                                        <li><a href="team.html">Team</a></li>
                                                        <li><a href="team-details.html">Team Details</a></li>
                                                        <li><a href="services-details.html">Services Details</a></li>
                                                        <li><a href="our-project-details.html">Our Project Details</a></li>
                                                        <li><a href="blog-details.html">Blog Details</a></li>
                                                        <li><a href="404.html">404</a></li>
                                                    </ul> */}
                                                </li>
                                                <li>
                                                    <a href="/contactus">Contact Us</a>
                                                </li>
                                                {/* <li>
                                                    <a href="blog.html">Blog</a>

                                                    <ul>
                                                        <li><a href="blog.html">Blog 1</a></li>
                                                        <li><a href="blog-standard.html">Blog 2</a></li>
                                                    </ul>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-header-wraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="header-logo d-block d-xl-none">
                                        <div className="logo">
                                            <a href="/">
                                                <img src="assets/img/logo_r.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>

                                    {/* <div className="social-profile last_no_bullet d-xl-block d-none">
                                        <ul>
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li> 
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div> */}

                                    

                                    <div className="header-right d-flex align-items-center">
                                        {/* <div className="header-search">
                                            <a className="search-toggle" data-selector=".header-search">
                                                <span className="fas fa-search"></span>
                                            </a>

                                            <form className="search-box" action="#" method="get">
                                                <div className="form-group d-flex align-items-center">
                                                    <input type="search" name="s" value="" className="search-input" id="search" placeholder="Search" />
                                                    <button type="submit" className="search-submit"><i className="fas fa-search"></i></button>
                                                </div>
                                            </form>
                                        </div> */}

                                        {/* <div className="horizontal-bar"></div> */}

                                        {/* <a className="shop-cart">
                                            <i className="fal fa-shopping-cart"></i>
                                            <span className="number">2</span>
                                        </a> */}

                                        <div className="mobile-nav-bar d-block ml-3 ml-sm-5 d-xl-none">
                                            <div className="mobile-nav-wrap">
                                                <div id="hamburger">
                                                    <i className="fal fa-bars"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* <!-- mobile menu - responsive menu  --> */}
            <div className="mobile-nav mobile-nav-yellow">
                <button type="button" className="close-nav">
                    <i className="fal fa-times-circle"></i>
                </button>

                <nav className="sidebar-nav">
                    <div className="navigation">
                        <div className="consulter-mobile-nav">
                            <ul>
                                <li>
                                    <a href="/">Home</a>
                                    {/* <ul>
                                        <li><a href="index.html">home 1</a></li>
                                        <li><a href="index-2.html">home 2</a></li>
                                        <li><a href="index-3.html">home 3</a></li>
                                        <li><a href="index-4.html">home 4</a></li>
                                        <li><a href="index-5.html">home 5</a></li>
                                    </ul> */}
                                </li>
                                <li>
                                    <a href="/services">Our Services</a>

                                    {/* <ul>
                                        <li><a href="services.html">Our Services 1</a></li>
                                        <li><a href="services-2.html">Our Services 2</a></li>
                                    </ul> */}
                                </li>
                                <li>
                                    <a href="/aboutus">About Us</a>

                                    {/* <ul>
                                        <li><a href="about.html">About</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                        <li><a href="table.html">Pricing Table</a></li>
                                        <li><a href="team.html">Team</a></li>
                                        <li><a href="team-details.html">Team Details</a></li>
                                        <li><a href="services-details.html">Services Details</a></li>
                                        <li><a href="our-project-details.html">Our Project Details</a></li>
                                        <li><a href="blog-details.html">Blog Details</a></li>
                                        <li><a href="404.html">404</a></li>
                                    </ul> */}
                                </li>
                                <li>
                                    <a href="/contactus">Contact Us</a>
                                </li>
                                {/* <li>
                                    <a href="blog.html">Blog</a>

                                    <ul>
                                        <li><a href="blog.html">Blog 1</a></li>
                                        <li><a href="blog-standard.html">Blog 2</a></li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>

                        <div className="sidebar-nav__bottom mt-20">
                            <div className="sidebar-nav__bottom-contact-infos mb-20">
                                <h6 className="color-black mb-5">Contact Info</h6>

                                <ul>
                                    <li><a><i className="fal fa-clock"></i> Mon – Fri: 10.00 – 10.00</a></li>
                                    <li><a href="mailto:support@metalinkbpo.com"><i className="icon-email"></i>support@metalinkbpo.com</a></li>
                                    <li>
                                        <a className="header-contact d-flex align-items-center">
                                            <div className="icon">
                                                <i className="icon-call"></i>
                                                {/* <!-- <img src="assets/img/icon/phone-1.svg" alt=""> --> */}
                                            </div>
                                            <div className="text">
                                                <span className="font-la mb-5 d-block fw-500">Contact For Support</span>
                                                <h5 className="fw-500">051-2755701</h5>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="sidebar-nav__bottom-social">
                                <h6 className="color-black mb-5">Follow On:</h6>

                                <ul>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            {/* <div className="offcanvas-overlay"></div> */}
            {/* <!-- offcanvas-overlay --> */}
            {/* <!-- header end --> */}
        </>
    );
});
