import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
    t: any;
    sendMessage: Function;
    loginResponce?: any;

}
interface State {
    email: string;
    showLoginLoading: boolean;
    emailColor: string;
    textArea: string;
    emailResponse: string;
    isWhitePaperRequest: boolean;
    error: {
        emailError: string;
    };
}

export class ServicesScreen extends PureComponent<Props, State> {


    constructor(props: Props) {
        super(props);
        this.state = {
            email: "",
            emailResponse: "",
            emailColor: "#FFFFFF",
            textArea: "",
            isWhitePaperRequest: false,
            showLoginLoading: false,
            error: {
                emailError: "",
            },
        };
    }

    componentDidMount() {
        // const script = document.createElement("script");
        // script.src = "assets/js/main.js";
        // script.async = true;
        // document.body.appendChild(script);
        // console.log(window.location.search);

        if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
            this.setState({ isWhitePaperRequest: true });
        }
    }
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    };

    handleSubmit = async () => {
        console.log('handled')

        let { email, textArea, error } = this.state;

        let formError = Utils.constant.validateForm({ email, textArea, error });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");

        this.setState({ error: Object.assign({}, formError) }, () => {
            if (this.state.error.emailError != "") {
                console.log(this.state.error)
                this.setState({ emailColor: "#d65454" })
            }
            else {
                console.log(this.state.error)
                this.setState({ emailColor: "#121212" })
            }

        });
        if (!isValid) {
            return false;
        } else {

            this.setState({ showLoginLoading: true });

            const token = await localStorage.getItem("fcmToken");

            let payload = this.state.isWhitePaperRequest ? { email, textArea, isWhitePaperRequest: "true" } : { email, textArea };

            this.props.sendMessage(payload, (response: any) => {
                this.setState(
                    {
                        email: "",
                        textArea: ""
                    },
                    () => {
                        this.setState({ emailResponse: "Sent" })

                        let element = document.getElementById("body-message");

                        if (element) {
                            element.classList.add("active");

                            let errorElement = document.getElementById("api-error-message");

                            if (errorElement) {
                                element.style.backgroundColor = "#00baee";
                                element.style.borderColor = "#00baee";
                                errorElement.innerHTML = "Sent";
                            }
                            setTimeout(function () {
                                if (element) {
                                    element.classList.remove("active");
                                }
                                if (errorElement) {
                                    errorElement.innerHTML = "";
                                }
                            }, 3000);
                        }

                    }
                );
            });
        }
    };

    // reset=( )=>{

    //   this.setState({

    //       email: "",
    //       emailResponse: "",
    //       emailColor: "#FFFFFF",
    //       textArea: "",
    //       isWhitePaperRequest:false,
    //       showLoginLoading: false,
    //       error: {
    //       emailError: "",
    //      },
    //   })

    // };

    render() {

        return (
            <>
                {/* <!-- page-banner start --> */}
                <section className="banner banner__2 overflow-hidden" style={{ backgroundImage: `url(assets/img/home-4/bg_01.png)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner__content text-center m-auto">
                                    <span className="sub-title fw-500  text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">Welcome To MetaLink</span>
                                    <h1 className="title mb-sm-10 mb-xs-5 mb-15">Employee Friendly<span> Services </span></h1>

                                    {/* <div className="description font-la mb-40 mb-lg-35 mb-sm-30 mb-xs-25">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div> */}

                                    <a href="/" className="theme-btn btn__2">Home<i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- page-banner end --> */}

                {/* <!-- digital-area end --> */}
                <section className="digital-area pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
                    <div className="container">
                        <div className="row mb-minus-30">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="why-choose__item">
                                    <div className="icon mb-30 mb-lg-20 mb-md-10 mb-xs-5 color-primary">
                                        <i className="icon-consultant"></i>
                                    </div>

                                    <h5 className="title color-d_black mb-15 mb-xs-10">HR Services</h5>

                                    <div className="description font-la">
                                        <p>Our HR Services are designed to streamline your human resources operations, allowing you to focus on growing your business. We offer a range of solutions including recruitment, onboarding, performance management, and HR compliance. Our experienced team understands the unique challenges of managing a workforce and can tailor strategies to suit your specific needs.</p>
                                    </div>

                                    <a className="" href="/aboutus">Read More <i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="why-choose__item">
                                    <div className="icon mb-30 mb-lg-20 mb-md-10 mb-xs-5 color-primary">
                                        <i className="icon-analysis"></i>
                                    </div>

                                    <h5 className="title color-d_black mb-15 mb-xs-10">Fintech Services</h5>

                                    <div className="description font-la">
                                        <p>In the fast-evolving world of finance and technology, our Fintech Services provide innovative solutions to help you stay ahead. From blockchain and cryptocurrency consulting to payment gateway integration, we offer a comprehensive suite of services that cater to the unique needs of the fintech industry. Let us empower your business with cutting-edge financial technology solutions.</p>
                                    </div>

                                    <a href="/aboutus">Read More <i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="why-choose__item">
                                    <div className="icon mb-30 mb-lg-20 mb-md-10 mb-xs-5 color-primary">
                                        <i className="icon-balance"></i>
                                    </div>

                                    <h5 className="title color-d_black mb-15 mb-xs-10">Customer Services</h5>

                                    <div className="description font-la">
                                        <p>Our Customer Services are crafted to meet the traditional needs of businesses across various industries. This includes accounting, legal advisory, and administrative support. Our seasoned professionals bring a wealth of experience to the table, ensuring that your business operations run smoothly and efficiently. Count on us for reliable and trusted Customer services.</p>
                                    </div>

                                    <a href="/aboutus">Read More <i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="why-choose__item">
                                    <div className="icon mb-30 mb-lg-20 mb-md-10 mb-xs-5 color-primary">
                                        <i className="icon-file"></i>
                                    </div>

                                    <h5 className="title color-d_black mb-15 mb-xs-10">Technology Services</h5>

                                    <div className="description font-la">
                                        <p>Embrace the future with our Technology Services that cover a wide spectrum of IT solutions. From software development and cloud integration to cybersecurity and IT consulting, we have the expertise to drive your business forward in the digital age. Leverage our technology prowess to enhance your operations, improve efficiency, and gain a competitive edge.</p>
                                    </div>

                                    <a href="/aboutus">Read More <i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- digital-area end --> */}

                {/* <!-- get-free end --> */}
                {/* <section className="get-free">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="get-free__content-wrapper overflow-hidden" style={{ backgroundImage: `url(assets/img/services/get-free-bg.png)` }}>
                                    <div className="get-free__content d-flex align-items-center justify-content-between">
                                        <div className="get-free__content-text">
                                            <h2 className="title mb-10 mb-xs-5 color-white">Get Free Quote</h2>

                                            <div className="description color-white font-la fw-500">
                                                Perfect solution for your Company
                                            </div>
                                        </div>

                                        <div className="get-free__content-form">
                                            <form className="d-flex flex-column flex-lg-row">
                                                <div className="single-personal-info">
                                                    <input type="text" placeholder="Your Name" />
                                                </div>
                                                <div className="single-personal-info">
                                                    <input type="email" placeholder="Your e-mail" />
                                                </div>

                                                <button type="submit" className="theme-btn btn-sm">Get Quote <i className="far fa-chevron-double-right"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <!-- get-free end --> */}

                {/* <!-- similar-work start --> */}
                <section className="similar-work similar-work-services  bg-dark_white pb-xs-80 pb-sm-100 pb-md-100 pb-120 overflow">
                    <div className="container">
                        <div className="row mb-minus-30">
                            <div className="col-lg-4 col-md-6">
                                <div className="similar-work__item reade-more-hidden mb-30 d-flex justify-content-between flex-column">
                                    <div className="top d-flex align-items-center">
                                        <div className="icon color-white text-center">
                                            <i className="fal fa-analytics"></i>
                                        </div>

                                        <h4 className="title color-d_black"><a href="/aboutus">HR Services</a></h4>
                                    </div>

                                    <div className="bottom">
                                        <div className="media overflow-hidden">
                                            <img src="assets/img/services/services-2.png" className="img-fluid" alt="" />
                                        </div>

                                        <a href="/aboutus" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="similar-work__item reade-more-hidden mb-30 d-flex justify-content-between flex-column">
                                    <div className="top d-flex align-items-center">
                                        <div className="icon color-white text-center">
                                            <i className="icon-planning"></i>
                                        </div>

                                        <h4 className="title color-d_black"><a href="/aboutus">Fintech Services</a></h4>
                                    </div>

                                    <div className="bottom">
                                        <div className="media overflow-hidden">
                                            <img src="assets/img/project-details/project-details-2.png" className="img-fluid" alt="" />
                                        </div>

                                        <a href="/aboutus" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="similar-work__item reade-more-hidden mb-30 d-flex justify-content-between flex-column">
                                    <div className="top d-flex align-items-center">
                                        <div className="icon color-white text-center">
                                            <i className="icon-outline"></i>
                                        </div>

                                        <h4 className="title color-d_black"><a href="/aboutus">Technology Services</a></h4>
                                    </div>

                                    <div className="bottom">
                                        <div className="media overflow-hidden">
                                            <img src="assets/img/project-details/project-details-3.png" className="img-fluid" alt="" />
                                        </div>

                                        <a href="/aboutus" className="theme-btn text-center fw-600">Read Details <i className="fas fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- similar-work end --> */}

                <div className="why-counter__overly overflow-hidden">
                    <div className="container">
                        <div className="overly"></div>
                    </div>
                    {/* <!-- why-choose start --> */}
                    <section className="why-choose pb-xs-80 pt-xs-80 pt-sm-100 pt-md-100 pt-120 pb-100 overflow-hidden">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="why-choose__media-wrapper d-flex flex-column">
                                        {/* <div className="video-play-btn">
                                            <a href="https://www.youtube.com/watch?v=9xwazD5SyVg" className="popup-video" data-effect="mfp-move-from-top"><i className="icon-play"></i></a>
                                        </div> */}

                                        <div className="why-choose__media">
                                            <img src="assets/img/services/why-choose-2.png" alt="" className="img-fluid" />
                                        </div>

                                        <div className="global-country text-center">
                                            <div className="number mb-5 color-white"><span className="counter">120</span>+</div>
                                            <h6 className="title color-white font-la">Global Country in <br />Our Company</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6">
                                    <div className="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                                        <div className="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                                            <span className="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-lg-20 mb-25"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Why choose us</span>
                                            <h2 className="title color-pd_black">Developing Solutions For The Future</h2>
                                        </div>

                                        {/* <div className="why-choose__item-wrapper d-grid justify-content-between">
                                            <div className="why-choose__item">
                                                <div className="icon mb-15 mb-md-10 mb-xs-5 color-primary">
                                                    <i className="icon-management"></i>
                                                </div>

                                                <h5 className="title color-d_black fw-500 mb-10">IT Management</h5>

                                                <div className="description font-la">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                                                </div>
                                            </div>

                                            <div className="why-choose__item">
                                                <div className="icon mb-15 mb-md-10 mb-xs-5 color-primary">
                                                    <i className="icon-lawyer"></i>
                                                </div>

                                                <h5 className="title color-d_black fw-500 mb-10">Lawyer Consulting</h5>

                                                <div className="description font-la">
                                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="faq mt-30 mt-sm-25 mt-xs-20" id="faq">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="h-faq-1">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-1" aria-expanded="true" aria-controls="faq-1">
                                                        <span>01.</span> How do you identify the specific problems my business is facing?
                                                    </button>
                                                </h2>

                                                <div id="faq-1" className="accordion-collapse collapse show" aria-labelledby="h-faq-1" data-bs-parent="#faq">
                                                    <div className="accordion-body">
                                                        <p>At MetaLink, we employ a meticulous approach to problem identification. In the first step, we conduct a comprehensive analysis of your business, taking into account its unique intricacies. This allows us to pinpoint the challenges you're facing and lay the foundation for targeted solutions.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="h-faq-2">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-2" aria-expanded="false" aria-controls="faq-2">
                                                        <span>02.</span> What sets your solutions apart from generic approaches?
                                                    </button>
                                                </h2>

                                                <div id="faq-2" className="accordion-collapse collapse" aria-labelledby="h-faq-2" data-bs-parent="#faq">
                                                    <div className="accordion-body">
                                                        <p>Our solutions are crafted with precision and tailored to your specific needs. We don't believe in one-size-fits-all approaches. Instead, we combine industry best practices with innovative techniques to create strategies that address the exact pain points of your business. This ensures that our solutions deliver tangible results.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="h-faq-3">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-3" aria-expanded="false" aria-controls="faq-3">
                                                        <span>03.</span> How do you ensure the success of the implemented solutions?
                                                    </button>
                                                </h2>

                                                <div id="faq-3" className="accordion-collapse collapse" aria-labelledby="h-faq-3" data-bs-parent="#faq">
                                                    <div className="accordion-body">
                                                        <p>At MetaLink, our commitment extends beyond offering solutions. We work closely with you to seamlessly integrate the strategies into your existing operations. This collaborative effort guarantees that the solutions not only meet your needs but also contribute to the overall success and growth of your business.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- why-choose end --> */}

                    {/* <!-- counter-area start --> */}
                    <div className="counter-area pb-sm-100 pb-xs-80 pb-md-100 pb-120 overflow-hidden">
                        <div className="container">
                            <div className="row mb-minus-30">
                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="counter-area__item d-flex align-items-center">
                                        <div className="icon color-primary">
                                            <i className="icon-process-1"></i>
                                        </div>

                                        <div className="text text-center">
                                            <div className="number fw-600 color-primary"><span className="counter">5620</span>+</div>
                                            <div className="description font-la">Successful Project</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="counter-area__item d-flex align-items-center">
                                        <div className="icon color-primary">
                                            <i className="icon-support-2"></i>
                                        </div>

                                        <div className="text text-center">
                                            <div className="number fw-600 color-primary"><span className="counter">150</span>+</div>
                                            <div className="description font-la">Expert Consultancy</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="counter-area__item d-flex align-items-center">
                                        <div className="icon color-primary">
                                            <i className="icon-coffee-2"></i>
                                        </div>

                                        <div className="text text-center">
                                            <div className="number fw-600 color-primary"><span className="counter">96</span>+</div>
                                            <div className="description font-la">Cup Of Cofee</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-4 col-sm-6">
                                    <div className="counter-area__item d-flex align-items-center">
                                        <div className="icon color-primary">
                                            <i className="icon-teamwork-1"></i>
                                        </div>

                                        <div className="text text-center">
                                            <div className="number fw-600 color-primary"><span className="counter">96</span>+</div>
                                            <div className="description font-la">Client Satisfaction</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- counter-area end --> */}
                </div>

                {/* <!-- can-help start --> */}
                {/* <section className="can-help services-two bg-dark_white pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-115 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 d-flex justify-content-center">
                                <div className="can-help__content  mb-sm-40 mb-xs-40 mb-md-45 mb-lg-50">
                                    <h2 className="title color-d_black mb-sm-15 mb-xs-10 mb-20">Experience The Evolution Of Your Business</h2>

                                    <div className="description font-la mb-md-25 mb-sm-25 mb-xs-20 mb-lg-30 mb-50">
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look</p>
                                    </div>

                                    <div className="help-text mb-md-25 mb-sm-25 mb-xs-20 mb-lg-25 mb-40">
                                        <a href="contact.html"><img src="assets/img/icon/question-comment.svg" className="img-fluid mr-xs-10 mr-20" alt="" />Need help? <span>Contact Us</span></a>
                                    </div>

                                    <div className="can-help__content-btn-group d-flex flex-column flex-sm-row">
                                        <a href="tel:+1235568824" className="theme-btn d-flex flex-column flex-md-row align-items-md-center">
                                            <div className="icon">
                                                <i className="icon-call"></i>
                                                
                                            </div>
                                            <div className="text">
                                                <span className="font-la mb-10 d-block fw-500 color-d_black">Call Us Everyday</span>
                                                <h5 className="fw-500 color-d_black">+123 556 8824</h5>
                                            </div>
                                        </a>

                                        <a href="mailto:consulter@gmail.com" className="theme-btn d-flex flex-column flex-md-row align-items-md-center">
                                            <div className="icon">
                                                <i className="icon-email-1"></i>
                                                
                                            </div>
                                            <div className="text">
                                                <span className="font-la mb-10 d-block fw-500 color-d_black">Email Drop Us</span>
                                                <h5 className="fw-500 color-d_black">consulter@gmail.com</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-5">
                                <div className="contact-form pt-md-30 pt-sm-25 pt-xs-20 pb-md-40 pb-sm-35 pb-xs-30 pt-xl-30 pb-xl-50 pt-45 pr-xl-50 pl-md-40 pl-sm-30 pl-xs-25 pr-md-40 pr-sm-30 pr-xs-25 pl-xl-50 pr-85 pb-60 pl-85">
                                    <div className="contact-form__header mb-sm-35 mb-xs-30 mb-40">
                                        <h6 className="sub-title fw-500 color-primary text-uppercase mb-15"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Get In Touch</h6>
                                        <h3 className="title color-d_black">Free Consultation</h3>
                                    </div>

                                    <form>
                                        <div className="single-personal-info">
                                            <input type="text" placeholder="Your Name" />
                                        </div>
                                        <div className="single-personal-info">
                                            <input type="email" placeholder="Your e-mail" />
                                        </div>
                                        <div className="single-personal-info">
                                            <input type="text" placeholder="Subject" />
                                        </div>
                                        <div className="single-personal-info">
                                            <textarea placeholder="Your Massage"></textarea>
                                        </div>

                                        <button type="submit" className="theme-btn btn-sm">Free Consultant <i className="far fa-chevron-double-right"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/* <!-- can-help end --> */}

                {/* <!-- testimonial start --> */}
                <section className="testimonial pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-110 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="testimonial__content mb-60 mb-md-50 mb-sm-40 mb-xs-30 text-center">
                                    <h6 className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt="" /> Testimonials</h6>
                                    <h2 className="title">MetaLink <span>Customer Feedback</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container container_testimonial">
                        <div className="row">
                            <div className="col-12">
                                <div className="testimonial-slider">
                                    <div className="slider-item">
                                        <div className="testimonial__item">
                                            <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                <div className="left d-flex align-items-center">
                                                    <div className="media overflow-hidden">
                                                        <img src="assets/img/testimonial/testimonial-1.png" className="img-fluid" alt="" />
                                                    </div>

                                                    <div className="meta">
                                                        <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam </h6>
                                                        <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                    </div>
                                                </div>

                                                <div className="right">
                                                    <div className="starts">
                                                        <ul>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                            </div>

                                            <div className="testimonial__item-footer d-flex justify-content-between">
                                                <div className="socail-link">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/img/testimonial/discord.png" alt="" /></a></li>
                                                        <li><a href="#"><img src="assets/img/testimonial/asana.png" alt="" /></a></li>
                                                    </ul>
                                                </div>

                                                <div className="quote color-primary">
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="testimonial__item border--primary">
                                            <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                <div className="left d-flex align-items-center">
                                                    <div className="media overflow-hidden">
                                                        <img src="assets/img/testimonial/testimonial-2.png" className="img-fluid" alt="" />
                                                    </div>

                                                    <div className="meta">
                                                        <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                        <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                    </div>
                                                </div>

                                                <div className="right">
                                                    <div className="starts">
                                                        <ul>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                            </div>

                                            <div className="testimonial__item-footer d-flex justify-content-between">
                                                <div className="socail-link">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/img/testimonial/asana.png" alt="" /></a></li>
                                                    </ul>
                                                </div>

                                                <div className="quote color-primary">
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="testimonial__item border-red">
                                            <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                <div className="left d-flex align-items-center">
                                                    <div className="media overflow-hidden">
                                                        <img src="assets/img/testimonial/testimonial-3.png" className="img-fluid" alt="" />
                                                    </div>

                                                    <div className="meta">
                                                        <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                        <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                    </div>
                                                </div>

                                                <div className="right">
                                                    <div className="starts">
                                                        <ul>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                            </div>

                                            <div className="testimonial__item-footer d-flex justify-content-between">
                                                <div className="socail-link">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/img/testimonial/amazon.png" alt="" /></a></li>
                                                    </ul>
                                                </div>

                                                <div className="quote color-primary">
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="testimonial__item border--primary">
                                            <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                <div className="left d-flex align-items-center">
                                                    <div className="media overflow-hidden">
                                                        <img src="assets/img/testimonial/testimonial-2.png" className="img-fluid" alt="" />
                                                    </div>

                                                    <div className="meta">
                                                        <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                        <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                    </div>
                                                </div>

                                                <div className="right">
                                                    <div className="starts">
                                                        <ul>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                            </div>

                                            <div className="testimonial__item-footer d-flex justify-content-between">
                                                <div className="socail-link">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/img/testimonial/asana.png" alt="" /></a></li>
                                                    </ul>
                                                </div>

                                                <div className="quote color-primary">
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="slider-item">
                                        <div className="testimonial__item border-red">
                                            <div className="testimonial__item-header d-flex justify-content-between align-items-center mb-35 mb-sm-25 mb-xs-20">
                                                <div className="left d-flex align-items-center">
                                                    <div className="media overflow-hidden">
                                                        <img src="assets/img/testimonial/testimonial-3.png" className="img-fluid" alt="" />
                                                    </div>

                                                    <div className="meta">
                                                        <h6 className="name fw-500 text-uppercase color-d_black">Md Ashikul Islam</h6>
                                                        <span className="position font-la fw-500 color-d_black">Graphic Designer</span>
                                                    </div>
                                                </div>

                                                <div className="right">
                                                    <div className="starts">
                                                        <ul>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                            <li><span></span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="description font-la mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                                            </div>

                                            <div className="testimonial__item-footer d-flex justify-content-between">
                                                <div className="socail-link">
                                                    <ul>
                                                        <li><a href="#"><img src="assets/img/testimonial/amazon.png" alt="" /></a></li>
                                                    </ul>
                                                </div>

                                                <div className="quote color-primary">
                                                    <i className="fas fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- testimonial end --> */}

                {/* <!-- client-brand start --> */}
                <div className="client-brand  pb-xs-80 pb-sm-100 pb-md-100 pb-105 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="client-brand__slider" data-slick='{
                        "dots": "false", 
                        "arrows": "false",
                        "autoplay": "true",
                        "slidesToShow": 6,
                        "infinite": "true",
                        "slidesToScroll": 1,
                        "autoplaySpeed": 500,
                        "responsive": [
                            {
                                "breakpoint": 1300,
                                "settings": {
                                "slidesToShow": 5
                                }
                            },    
                            {
                              "breakpoint": 1200,
                              "settings": {
                                "slidesToShow": 4
                              }
                            },
                            {
                                "breakpoint": 992,
                                "settings": {
                                  "slidesToShow": 3
                                }
                            },
                            {
                                "breakpoint": 768,
                                "settings": {
                                  "slidesToShow": 2
                                }
                            },
                            {
                              "breakpoint": 481,
                              "settings": {
                                "slidesToShow": 1
                              }
                            }
                        ]
                        }'>
                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-1.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-2.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-3.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-4.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-5.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-6.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-1.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-2.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-3.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-4.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-5.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="slider-item">
                                        <a href="#" className="client-brand__item">
                                            <div className="client-brand__item-media">
                                                <img src="assets/img/brand/brand-6.png" className="img-fluid" alt="" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- client-brand end --> */}
            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        sendMessage: (payload: any, callback: Function) =>
            dispatch(sendMessage(payload, callback)),
    };
}

export const ServicesModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ServicesScreen)
);

export const Services = AppLayout(ServicesModule);
