import React, { useEffect } from "react";
import { BrowserRouter, useLocation,Routes, Route } from "react-router-dom";
// import {
//     Login
// } from "../modules/auth";

import { Home } from "../modules/home";
import { AboutUs } from "../modules/aboutus";
import { Cloud } from "../modules/cloud";
import { ContactUs } from "../modules/contactus";
import { Domain } from "../modules/domain";
import { SharedHosting } from "../modules/sharedhosting";
// import { Servers } from "../modules/servers";
import { Services } from "../modules/services";
import { Login } from "../modules/login";


// function ScrollToTop() {
//     const { pathname } = useLocation();
  
//     useEffect(() => {
//       window.scrollTo(0, 0);
//     }, [pathname]);
  
//     return null;
//   }

  function Router() {
    return(
        <BrowserRouter>
        <Routes>
            {/* @ts-ignore */}
            <Route path="/" element={<Home/>} />
            {/* @ts-ignore */}
            <Route path="/login" element={<Login/>} />
            {/* @ts-ignore */}
            <Route path="/aboutus" element={<AboutUs/>} />
            {/* @ts-ignore */}
            <Route path="/cloud" element={<Cloud/>} />
            {/* @ts-ignore */}
            <Route path="/shared" element={<SharedHosting/>} />
            {/* @ts-ignore */}
            <Route path="/services" element={<Services/>} />
            {/* @ts-ignore */}
            <Route path="/domains" element={<Domain/>} />
            {/* @ts-ignore */}
            <Route path="/contactus" element={<ContactUs/>} />
        </Routes>
        </BrowserRouter>
    )
  }

  export default Router;