import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
  t: any;
  sendMessage: Function;
  loginResponce?: any;
}
interface State {
  name: string;
  nameColor: string;

  email: string;
  message: string;
  messageColor: string;

  subject: string;
  subjectColor: string;

  phone: string;
  phoneColor: string;

  showLoginLoading: boolean;

  formSubmitted: string;
  emailColor: string;
  textArea: string;
  emailResponse: string;
  isWhitePaperRequest: boolean;
  error: {
    emailError: string;
    nameError: string;
    subjectError: string;
    messageError: string;
    phoneError: string;
  };
}

export class ContactUsScreen extends PureComponent<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      nameColor: "#FFFFFF",

      message: "",
      messageColor: "#FFFFFF",

      email: "",
      subject: "",
      subjectColor: "#FFFFFF",

      phone: "",
      phoneColor: "#FFFFFF",
      emailResponse: "",
      emailColor: "#FFFFFF",
      textArea: "",

      formSubmitted: "0",
      isWhitePaperRequest: false,
      showLoginLoading: false,
      error: {
        emailError: "",
        nameError: "",
        subjectError: "",
        messageError: "",
        phoneError: ""
      },
    };
  }

  componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "assets/js/main.js";
    // script.async = true;
    // document.body.appendChild(script);
    // console.log(window.location.search);

    if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
      this.setState({ isWhitePaperRequest: true });
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    console.log('handled')

    let { email, message, name, subject, error } = this.state;

    let formError = Utils.constant.validateForm({ email, message, name, subject, error, });

    let isValid = Object.keys(formError).every((k) => formError[k] === "");

    this.setState({ error: Object.assign({}, formError) }, () => {
      // Email
      if (this.state.error.emailError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "#121212" })
      }
      // Name 
      if (this.state.error.nameError != "") {
        console.log(this.state.error)
        this.setState({ nameColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ nameColor: "#121212" })
      }
      // Message 
      if (this.state.error.messageError != "") {
        console.log(this.state.error)
        this.setState({ messageColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ messageColor: "#121212" })
      }
      // Subject 
      if (this.state.error.subjectError != "") {
        console.log(this.state.error)
        this.setState({ subjectColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ subjectColor: "#121212" })
      }
      // Phone
      if (this.state.error.phoneError != "") {
        console.log(this.state.error)
        this.setState({ phoneColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ phoneColor: "#121212" })
      }
    });
    if (!isValid) {
      return false;
    } else {

      this.setState({ showLoginLoading: true });

      const token = await localStorage.getItem("fcmToken");

      let payload = this.state.isWhitePaperRequest ? { email, message, name, subject, isWhitePaperRequest: "true" } : { email, message, subject, name, };

      this.props.sendMessage(payload, (response: any) => {
        console.log(response);
        console.log(response.message);
        if (response.message == "Account Approved Email Sent") {
          this.setState({ formSubmitted: "1" })
        }
        else {
          this.setState({ formSubmitted: "2" })
        }
        this.setState(
          {
            email: "",
            message: "",
            name: "",
            subject: "",
            phone: ""
          },
          () => {
            this.setState({ emailResponse: "Sent" })

            let element = document.getElementById("body-message");

            if (element) {
              element.classList.add("active");

              let errorElement = document.getElementById("api-error-message");

              if (errorElement) {
                element.style.backgroundColor = "#00baee";
                element.style.borderColor = "#00baee";
                errorElement.innerHTML = "Sent";
              }
              setTimeout(function () {
                if (element) {
                  element.classList.remove("active");
                }
                if (errorElement) {
                  errorElement.innerHTML = "";
                }
              }, 3000);
            }

          }
        );
      });
    }
  };

  // reset=( )=>{

  //   this.setState({

  //       email: "",
  //       emailResponse: "",
  //       emailColor: "#FFFFFF",
  //       textArea: "",
  //       isWhitePaperRequest:false,
  //       showLoginLoading: false,
  //       error: {
  //       emailError: "",
  //      },
  //   })

  // };

  render() {

    return (
      <>
         <section className="banner banner__2 overflow-hidden" style={{ backgroundImage: `url(assets/img/home-5/bg_02.png)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner__content text-center m-auto">
                                    <span className="sub-title fw-500  text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">Welcome To MetaLink</span>
                                    <h1 className="title mb-sm-10 mb-xs-5 mb-15">Contact With <span> Us </span></h1>

                                    {/* <div className="description font-la mb-40 mb-lg-35 mb-sm-30 mb-xs-25">
                                        <p>Schedule time to review the engineers who can help your team focus on critical features.</p>
                                    </div> */}

                                    <a href="/" className="theme-btn btn__2">Home<i className="far fa-chevron-double-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        <section className="contact-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="contact-us__content">
                        <h6 className="sub-title fw-500 color-primary text-uppercase mb-sm-15 mb-xs-10 mb-20"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt=""/> contact us with Ease</h6>
                        <h2 className="title color-d_black mb-sm-15 mb-xs-10 mb-20">Get in Touch</h2>

                        <div className="description font-la">
                            <p>Schedule time to review the engineers who can help your team focus on critical features.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="row contact-us__item-wrapper mt-xs-35 mt-sm-40 mt-md-45">
                        <div className="col-sm-6">
                            <div className="contact-us__item mb-40">
                                <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                                    <div className="icon mr-10 color-primary">
                                        <i className="fal fa-map-marker-alt"></i>
                                    </div>
                                    
                                    <h5 className="title color-d_black">Rawalpindi Office</h5>
                                </div>

                                <div className="contact-us__item-body font-la">
                                Office 401, 4th Floor, Rawal Trade Center (RTC), Haider Road, Rawalpindi
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-sm-6">
                            <div className="contact-us__item mb-40">
                                <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                                    <div className="icon mr-10 color-primary">
                                        <i className="icon-home-location"></i>
                                    </div>
                                    
                                    <h5 className="title color-d_black">Ontario Office</h5>
                                </div>

                                <div className="contact-us__item-body font-la">
                                    3891 Ranchview Dr. Richardson, California 62639
                                </div>
                            </div>
                        </div> */}

                        <div className="col-sm-6">
                            <div className="contact-us__item mb-40">
                                <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                                    <div className="icon mr-10 color-primary">
                                        <i className="icon-phone"></i>
                                    </div>
                                    
                                    <h5 className="title color-d_black">Call Us Today</h5>
                                </div>

                                <div className="contact-us__item-body font-la">
                                    <ul>
                                        <li><a href="tell:051-2755701">051-2755701</a></li>
                                        {/* <li><a href="tell:(208)555-0112">(208) 555-0112</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="contact-us__item mb-40">
                                <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                                    <div className="icon mr-10 color-primary">
                                        <i className="icon-email"></i>
                                    </div>
                                    
                                    <h5 className="title color-d_black">Email Us</h5>
                                </div>

                                <div className="contact-us__item-body font-la">
                                    <ul>
                                        <li><a href="mailto:support@metalinkbpo.com">support@metalinkbpo.com </a></li>
                                        {/* <li><a href="mailto:sara.cruz@example.com">sara.cruz@example.com</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="contact-us__item mb-40">
                                <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                                    <div className="icon mr-10 color-primary">
                                        <i className="icon-email"></i>
                                    </div>
                                    
                                    <h5 className="title color-d_black">Social Accounts</h5>
                                </div>

                                <div className="contact-us__item-body font-la">
                                {/* <div className="social-profile last_no_bullet d-xl-block d-none"> */}
                                        <ul>
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li> 
                                            {/* <li><a href="#"><i className="fab fa-twitter"></i></a></li> */}
                                            {/* <li><a href="#"><i className="fab fa-instagram"></i></a></li> */}
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <hr className="mt-md-45 mt-sm-30 mt-xs-30 mt-60"/>
                </div>
            </div>
        </div>
    </section>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    sendMessage: (payload: any, callback: Function) =>
      dispatch(sendMessage(payload, callback)),
  };
}

export const ContactUsModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen)
);

export const ContactUs = AppLayout(ContactUsModule);




