import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
  t: any;
  sendMessage: Function;
  loginResponce?: any;

}
interface State {
  name: string;
  nameColor: string;

  email: string;
  message: string;
  messageColor: string;

  subject: string;
  subjectColor: string;

  phone: string;
  phoneColor: string;

  showLoginLoading: boolean;

  formSubmitted: string;
  emailColor: string;
  textArea: string;
  emailResponse: string;
  isWhitePaperRequest: boolean;
  error: {
    emailError: string;
    nameError: string;
    subjectError: string;
    messageError: string;
    phoneError: string;
  };
}

export class LoginScreen extends PureComponent<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      nameColor: "#FFFFFF",

      message: "",
      messageColor: "#FFFFFF",

      email: "",
      subject: "",
      subjectColor: "#FFFFFF",

      phone: "",
      phoneColor: "#FFFFFF",
      emailResponse: "",
      emailColor: "#FFFFFF",
      textArea: "",

      formSubmitted: "0",
      isWhitePaperRequest: false,
      showLoginLoading: false,
      error: {
        emailError: "",
        nameError: "",
        subjectError: "",
        messageError: "",
        phoneError: ""
      },
    };
  }

  componentDidMount() {

    console.log(window.location.search);

    if (window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper")) {
      this.setState({ isWhitePaperRequest: true });
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    console.log('handled')

    let { email, message, name, subject, error } = this.state;

    let formError = Utils.constant.validateForm({ email, message, name, subject, error, });

    let isValid = Object.keys(formError).every((k) => formError[k] === "");

    this.setState({ error: Object.assign({}, formError) }, () => {
      // Email
      if (this.state.error.emailError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "#121212" })
      }
      // Name 
      if (this.state.error.nameError != "") {
        console.log(this.state.error)
        this.setState({ nameColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ nameColor: "#121212" })
      }
      // Message 
      if (this.state.error.messageError != "") {
        console.log(this.state.error)
        this.setState({ messageColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ messageColor: "#121212" })
      }
      // Subject 
      if (this.state.error.subjectError != "") {
        console.log(this.state.error)
        this.setState({ subjectColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ subjectColor: "#121212" })
      }
      // Phone
      if (this.state.error.phoneError != "") {
        console.log(this.state.error)
        this.setState({ phoneColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ phoneColor: "#121212" })
      }
    });
    if (!isValid) {
      return false;
    } else {

      this.setState({ showLoginLoading: true });

      const token = await localStorage.getItem("fcmToken");

      let payload = this.state.isWhitePaperRequest ? { email, message, name, subject, isWhitePaperRequest: "true" } : { email, message, subject, name, };

      this.props.sendMessage(payload, (response: any) => {
        console.log(response);
        console.log(response.message);
        if (response.message == "Account Approved Email Sent") {
          this.setState({ formSubmitted: "1" })
        }
        else {
          this.setState({ formSubmitted: "2" })
        }
        this.setState(
          {
            email: "",
            message: "",
            name: "",
            subject: "",
            phone: ""
          },
          () => {
            this.setState({ emailResponse: "Sent" })

            let element = document.getElementById("body-message");

            if (element) {
              element.classList.add("active");

              let errorElement = document.getElementById("api-error-message");

              if (errorElement) {
                element.style.backgroundColor = "#00baee";
                element.style.borderColor = "#00baee";
                errorElement.innerHTML = "Sent";
              }
              setTimeout(function () {
                if (element) {
                  element.classList.remove("active");
                }
                if (errorElement) {
                  errorElement.innerHTML = "";
                }
              }, 3000);
            }

          }
        );
      });
    }
  };

  // reset=( )=>{

  //   this.setState({

  //       email: "",
  //       emailResponse: "",
  //       emailColor: "#FFFFFF",
  //       textArea: "",
  //       isWhitePaperRequest:false,
  //       showLoginLoading: false,
  //       error: {
  //       emailError: "",
  //      },
  //   })

  // };

  render() {

    return (
      <>
            {/* <!-- Breadcrumps --> */}
    <div className="breadcrumbs">
        <div className="row">
            <div className="col-sm-6">
                <h1>Login</h1>
            </div>
            <div className="col-sm-6">
                <ol className="breadcrumb">
                    <li>You are here: </li>
                    <li><a href="index-2.html">Home</a>
                    </li>
                    <li>Pages
                    </li>
                    <li className="active">Login</li>
                </ol>
            </div>
        </div>
    </div>
    {/* <!-- End of Breadcrumps --> */}

    {/* <!-- Login --> */}
    <section className="login">

        <div className="row spacing-40">
            <div className="col-sm-12">
                <div className="login-form-panel">
                    <h3 className="badge">LOGIN</h3>


                    <div className="row">
                        <div className="col-sm-5 center-block">
                            <div className="login-form">
                                <form method="post" action="#">
                                    <input type="text" name="username" size={50} placeholder="E-mail address" />
                                    <input type="password" name="password" size={20} placeholder="Password" />
                                    <p className="text-center"><a href="#">Forgot Password?</a>
                                    </p>

                                    <input type="submit" value="Login" />
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </section>
    {/* <!-- End of Login --> */}
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    sendMessage: (payload: any, callback: Function) =>
      dispatch(sendMessage(payload, callback)),
  };
}

export const LoginModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
);

export const Login = AppLayout(LoginModule);




