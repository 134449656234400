import React, { FunctionComponent, useEffect } from "react";
//import { Right } from "react-bootstrap/lib/Media";
import { useTranslation } from "react-i18next";
// import animation from './footer_shape_2.svg'

interface Props { }
interface State { }

const Footer: FunctionComponent = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "assets/js/main.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    console.log('++++', useEffect);

    const { t, i18n } = useTranslation();

    return (
        <>
        <footer className="footer-1 footer-3 overflow-hidden" >
        <div className="overly">
            <div className="container"></div>
        </div>

        <div className="footer-top__box pb-100 pb-lg-80 pb-md-70 pb-sm-60 pb-xs-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-top__box-wrapper d-flex flex-column flex-sm-row text-center text-sm-start justify-content-sm-between align-items-center" style={{backgroundImage: `url(assets/img/footer/footer-box-bg.png)`}}>
                            <div className="text">
                                <h3 className="title color-white">Get updated Informed to Subscribe our Newsletter</h3>
                            </div>

                            <div className="theme-btn__wrapper d-flex flex-column"> 
                                <input type="email" className="theme-btn" placeholder="Your e-mail"/>
                                <a href="#" className="theme-btn">Subscribe Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row justify-content-between">
                <div className="col-md-6 col-xl-3">
                    <div className="single-footer-wid widget-description">
                        <a href="index.html" className="d-block mb-30 mb-xs-20">
                            <img src="assets/img/metalink_icon_resize.png" alt=""/>
                        </a>
                        
                        <div className="description font-la color-white mb-40 mb-sm-30 mb-xs-25">
                            <p>We envision a world where businesses thrive through innovation, strategic insights, and seamless operations. By combining expertise in HR, Fintech, Customer, and Technology services, we are committed to shaping a brighter future for our clients.</p>
                        </div>

                        {/* <a href="#" className="theme-btn btn-red btn-md fw-600">Purchase Now <i className="far fa-chevron-double-right"></i></a> */}
                    </div>
                </div> 
                {/* <!-- /.col-lg-3 - single-footer-wid --> */}

                <div className="col-md-6 col-xl-2">                        
                    <div className="single-footer-wid pl-xl-10 pl-50">
                        <h4 className="wid-title mb-30 color-white">Quick Link</h4>
                        
                        <ul>
                            <li><a href="/aboutus">About Company</a></li>
                            <li><a href="/services">Our Services</a></li>
                            <li><a href="#">Investor Presentation</a></li>
                            <li><a href="#">Investor Career</a></li>
                            <li><a href="#">Meet Our Team</a></li>
                            <li><a href="#">Support</a></li>
                        </ul>
                    </div>
                </div> 
                {/* <!-- /.col-lg-2 - single-footer-wid --> */}

                <div className="col-md-6 col-xl-4">
                    <div className="single-footer-wid recent_post_widget pl-xl-10 pl-65 pr-50 pr-xl-30">
                        <h4 className="wid-title mb-30 color-white">Resent Post</h4>

                        <div className="recent-post-list">
                            <a href="blog.html" className="single-recent-post mb-20 pb-20 d-flex align-items-center">
                                <div className="thumb">
                                    <img src="assets/img/footer/resent-post-1.png" alt=""/>
                                </div>

                                <div className="post-data">
                                    <h5 className="color-white mb-10 fw-600">Why Purpose-Driven Employers Succeed</h5>
                                    <span className="color-white d-flex ailign-items-center"><i className="far fa-clock"></i>January 11, 2018</span>
                                </div>
                            </a>

                            <a href="blog.html" className="single-recent-post mb-20 pb-20 d-flex align-items-center">
                                <div className="thumb">
                                    <img src="assets/img/footer/resent-post-2.png" alt=""/>
                                </div>

                                <div className="post-data">
                                    <h5 className="color-white mb-10 fw-600">Saving Time Achieving Success in Business</h5>
                                    <span className="color-white d-flex ailign-items-center"><i className="far fa-clock"></i>november 12, 2019</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div> 
                {/* <!-- /.col-lg-4 - single-footer-wid --> */}

                {/* <div className="col-md-6 col-xl-3">                        
                    <div className="single-footer-wid">
                        <h4 className="wid-title mb-30 color-white">Office Location</h4>

                        <div id="map" className="pinRed"></div>
                    </div>
                </div>  */}
                {/* <!-- /.col-lg-3 - single-footer-wid --> */}
            </div>
        </div>

        <div className="footer-bottom overflow-hidden mt-20 mt-sm-15 mt-xs-10">
            <div className="container">
                <div className="footer-bottom-content d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className="coppyright text-center text-md-start">
                        &copy; 2022 <a href="index.html">MetaLink</a> | All Rights Reserved by <a href="#">MetaLink</a>
                    </div>

                    <div className="footer-bottom-list last_no_bullet">
                        <ul>
                            <li><a href="#">Terms & Conditions</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="elfsight-app-4946ba03-7864-4fce-925e-5c51e5b24550" data-elfsight-app-lazy></div>
    </footer>
        </>
    );
};

export default Footer;