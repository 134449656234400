import Utils from "../../utils";
import { UserDataModal, ErrorResponseModal, changePasswordModel } from "../../modal";

export function doLogin(payload: any, callback: Function = function () {}) {
  return (dispatch: Function, getState: Function) => {
    console.log('enter')
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.adminLogin;
    payload.device_id = "device123";
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        console.log(response);
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: UserDataModal = response.data.data;
          console.log(data);
          let sessionData = {
            accessToken: data.authToken,
            user_id: data.user_id,
            full_name:
              data.fullName && !data.fullName.includes("undefined")
                ? data.fullName
                : `${data.first_name} ${data.last_name}`,
            first_name: data.first_name,
            last_name: data.last_name,
           // user_image: data.user_image,
            // twitter_user_name: data.twitterUsername,
            role:data.role,
          };
          console.log(sessionData);
          Utils.constant.setSession(sessionData);
          dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
          callback(sessionData);
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
          callback({ loginerror: true });
        }
      },
      function (error: any) {
        Utils.constant.apiErrorMessage(error.data.message);
        let data: ErrorResponseModal = error.data;
        dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
        callback({ loginerror: true });
      }
    );
  };
}

export function doRegister(payload: any, callback: Function = function () {}) {
  return (dispatch: Function, getState: Function) => {
    console.log('enter')
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.signup;
    payload.device_id = "device123";
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        console.log(response);
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: UserDataModal = response.data.data;
          console.log(data);
          let sessionData = {
            accessToken: data.authToken,
            user_id: data.user_id,
            // full_name:
            //   data.fullName && !data.fullName.includes("undefined")
            //     ? data.fullName
            //     : `${data.first_name}`,
            // first_name: data.first_name,
            // last_name: data.last_name,
           // user_image: data.user_image,
            // twitter_user_name: data.twitterUsername,
            role:data.role,
          };
          console.log(sessionData);
          Utils.constant.setSession(sessionData);
          dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
          callback(sessionData);
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
          callback({ loginerror: true });
        }
      },
      function (error: any) {
        Utils.constant.apiErrorMessage(error.data.message);
        let data: ErrorResponseModal = error.data;
        dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
        callback({ loginerror: true });
      }
    );
  };
}

export function doUnsubscribe(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.unsubscribe;
    payload.device_id = "device123";
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: UserDataModal = response.data;

          dispatch({ type: Utils.actionName.UNSUBSCRIBE_EMAIL, payload: data });
          callback(data);
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
        }
      },
      function (error: any) {
        Utils.constant.apiErrorMessage(error.data.message);
        let data: ErrorResponseModal = error.data;
        dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
      }
    );
  };
}

export function doSignup(
  payload: any,
  tokens: any,
  callback: Function = function () {}
) {
 // return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    console.log("hitting")
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.signup;
    payload.device_token = tokens.fcmToken;
    payload.fcm_token = tokens.fcmToken;
    payload.device_type = "3";
    payload.user_type = Utils.constant.userType.recruiter;
    payload.device_id = "device123";

    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        console.log('back to call')
        console.log(response)
        if (response.status) {
          let data: UserDataModal = response.data.data;
          let sessionData = {
            accessToken: data.authToken,
            userId: data.userId,
            memberId:data.memberId,
            firstName:data.firstName,
            lastName: data.lastName,
            accountType:data.accountType,

            // role:data.role,
            // full_name:
            //   data.fullName && !data.fullName.includes("undefined")
            //     ? data.fullName
            //     : `${data.first_name} ${data.last_name}`,
          };
          Utils.constant.setSession(sessionData);
          //dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
          callback(sessionData);
        }
      },
      function (error: any) {
        console.log(error)
        Utils.constant.apiErrorMessage(error.data.message);
        callback({ signuperror: true });
        let data: ErrorResponseModal = error.data;
        //dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
      }
    );
  //};
}

export function adminSignup(
  payload: any,
  tokens: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    console.log("hitting")
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.adminSignup;
    payload.device_token = tokens.fcmToken;
    payload.fcm_token = tokens.fcmToken;
    payload.device_type = "3";
    payload.user_type = Utils.constant.userType.recruiter;
    payload.device_id = "device123";

    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        console.log('back to call')
        console.log(response)
        if (response.status) {
          let data: UserDataModal = response.data.data;
          let sessionData = {
            accessToken: data.authToken,
            user_id: data.user_id,
            role:data.role,
            full_name:
              data.fullName && !data.fullName.includes("undefined")
                ? data.fullName
                : `${data.first_name} ${data.last_name}`,
          };
          Utils.constant.setSession(sessionData);
         // dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
          callback(sessionData);
        }
      },
      function (error: any) {
        console.log(error)
        Utils.constant.apiErrorMessage(error.data.message);
        callback({ signuperror: true });
        let data: ErrorResponseModal = error.data;
        //dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
      }
    );
  };
}

export function doForgotPassword(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.forgot;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          callback();
        }
      },
      function (error: any) {
        if (error.status === 400) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function doSocialmediaLogin(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.socialmediaLogin;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: UserDataModal = response.data.data;
          let sessionData = {
            accessToken: data.authToken,
            user_id: data.user_id,
            full_name:
              data.fullName && !data.fullName.includes("undefined")
                ? data.fullName
                : `${data.first_name} ${data.last_name}`,
            is_profile_added: data.is_profile_added,
            isOtpVerified: data.company.isOtpVerified,
            recruiterPic: data.user_image ? data.user_image : "",
            tourEnable: data.tourEnable ? data.tourEnable : {},
          };
          Utils.constant.setSession(sessionData);
          dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
          callback(response.data.data);
        }
      },
      function (error: any) {
        if (error.status === 400) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
        let data: ErrorResponseModal = error.data;
        dispatch({ type: Utils.actionName.HANDLE_USER_DATA, payload: data });
      }
    );
  };
}

export function changePassword(
  payload: any,
  token: string,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.chnagePassword;
    Utils.api.postApiCallForOtp(
      endPoint,
      payload,
      token,
      function (response: any) {
        
        if (response.status) {
          let data: changePasswordModel = response.data;
          dispatch({
            type: Utils.actionName.CHANGE_PASSWORD,
            payload: { data },
          });
          callback(data);
        }
      },
      function (error: any) {
        console.log("the message is ", error)
        callback(error)
       // Utils.constant.apiErrorMessage("Wrong current password");
      }
    );
  };
}
